body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif !important;
  outline: none !important;
}

:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

body main {
  background: $container-color;
}

main cx-page-layout.LandingPage2Template {
  background: $container-color;
  padding: 15px;
  max-width: 100%;
  margin-top: 108px;
}

.LandingPage2Template cx-page-slot.Section1 {
  max-width: 100% !important;
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 5px;
}

.btn-primary {
  font-size: 1rem;
  height: auto;
  border-radius: 0.25rem;
  border: 0px solid var(--cx-color-primary);

  &:hover {
    color: var(--cx-spinner-secondary-color);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-primary);
  }

  &:focus {
    color: var(--cx-color-inverse);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-primary);
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-inverse);
    border-color: var(--cx-color-primary) !important;
    background-color: var(--cx-color-primary) !important;
  }

  &:disabled {
    color: var(--cx-color-inverse);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-primary);
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.btn-secondary {
  font-size: 1rem;
  height: auto;
}

.btn-outline-primary {
  font-size: 1rem;
  height: auto;
  color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  font-weight: normal;
  border-radius: 0.25rem;

  &:hover {
    color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
  }

  &:disabled {
    color: var(--cx-color-light);
    border-color: var(--cx-color-light);
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.btn-action {
  @extend .btn-outline-text !optional;
  border-width: 2px;
  border-style: solid;
  font-size: 1rem;
  height: auto;
  border-radius: 0.25rem;

  &:hover {
    color: var(--cx-color-primary);
    border-width: 2px;
    border-style: solid;
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-inverse);
    border-width: 2px;
    border-style: solid;
    background-color: var(--cx-color-primary);
  }

  &:disabled {
    color: var(--cx-color-light);
    border-width: 2px;
    border-style: solid;
    border-color: var(--cx-color-light);
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.btn-loadmore {
  font-size: 1rem;
  height: auto;
  color: var(--cx-color-inverse);
  background: $filter-color;
  border: 0;
  width: auto;
  border-radius: 0.25rem;
  line-height: 1.34;

  &:hover {
    color: var(--cx-color-inverse);
    background-color: $filter-color;
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-inverse);
    background-color: var(--cx-color-primary);
  }
}

.download-links {
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: var(--cx-color-primary);
    text-decoration: none;
  }
}

.hover-link {
  &:hover {
    color: var(--cx-color-inverse);
    text-decoration: none;
  }
}

.menu-inner-titles {
  &:hover {
    background: $gray-300;
  }
}

.custom-control {
  position: relative;
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: $custom-control-indicator-size;
  height: ($font-size-base * $line-height-base + $custom-control-indicator-size) / 2;
  opacity: 0;

  &:checked~.custom-control-label::before {
    color: $custom-control-indicator-checked-color;
    border-color: var(--cx-color-primary);
    @include gradient-bg(var(--cx-color-primary));
    @include box-shadow($box-shadow-0);
  }

  &:focus~.custom-control-label::before {

    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $box-shadow-0;
    }

    @else {
      box-shadow: $box-shadow-0;
    }
  }

  &:focus:not(:checked)~.custom-control-label::before {
    border-color: var(--cx-color-primary);
    @include box-shadow($box-shadow-0);
  }

  &:not(:disabled):active~.custom-control-label::before {
    color: $custom-control-indicator-active-color;
    background-color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
  }
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: '';
    background-color: $custom-control-indicator-bg;
    border: $filter-color solid 2px;
    @include box-shadow($custom-control-indicator-box-shadow);
  }
}

// Select all checkbox styling

.select-all .custom-control {
  position: relative;
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
}

.select-all .custom-control-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.select-all .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: $custom-control-indicator-size;
  height: ($font-size-base * $line-height-base + $custom-control-indicator-size) / 2;
  opacity: 0;

  &:checked~.custom-control-label::before {
    color: var(--cx-color-primary);
    border-color: var(--cx-color-inverse);
    @include gradient-bg($white);
    @include box-shadow($box-shadow-0);
  }

  &:focus~.custom-control-label::before {

    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $box-shadow-0;
    }

    @else {
      box-shadow: $box-shadow-0;
    }
  }

  &:focus:not(:checked)~.custom-control-label::before {
    border-color: var(--cx-color-inverse);
    @include box-shadow($box-shadow-0);
  }

  &:not(:disabled):active~.custom-control-label::before {
    color: $custom-control-indicator-active-color;
    background-color: var(--cx-color-inverse);
    border-color: var(--cx-color-inverse);
  }
}

.select-all .custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: '';
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    @include box-shadow($custom-control-indicator-box-shadow);
  }
}

.select-all .custom-control-input:checked~.custom-control-label {
  &::after {
    position: absolute;
    top: 9px;
    left: -20px;
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: '';
    background: no-repeat #{url(../images/select-all.png)};
  }
}

.reference-checkbox input[type='radio'] {
  border-radius: 50%;
  display: inline-block;
  border-style: solid;
  outline: none;
  border-width: 2px;
  border-color: $filter-color;
  position: absolute;
  top: -7px;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--cx-color-inverse);
    left: 4px;
    top: 4px;
  }

  &:checked {
    border-color: $filter-color !important;
    background-color: var(--cx-color-inverse);

    &::after {
      background-color: var(--cx-color-primary);
    }
  }
}

a:hover {
  color: var(--cx-color-primary);
}

.MultiStepCheckoutSummaryPageTemplate,
.KnBrOrderConfirmationPageTemplate {
  cx-promotions .cx-promotions:empty {
    display: none;
  }
}

.brand:empty {
  display: none;
}

.KnBrQuoteDetailPageTemplate,
.KnBrDraftOrderDetailPageTemplate {
  kn-br-product-quick-entry:empty {
    display: none;
  }
}

.LandingPage2Template cx-page-slot.Section2.has-components {
  background: var(--cx-color-inverse);
  margin-top: 15px;
  padding: 15px;
  border-radius: 4px;
}

.product-grid-hover mat-card {
  width: 100%;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: var(--cx-color-primary);
  border-radius: 34px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--cx-color-primary);
}

button {
  outline: none !important;
  box-shadow: none !important;
}

.bg-white.rounded {
  min-height: calc(100vh - 356px) !important;
}

// Login Styling starts
.login-wrapper {
  position: relative;
  position: absolute;
  top: 190px;
}

.login-wrapper img.login-back {
  width: 100%;
}

.remember-me input {
  border-radius: $custom-checkbox-indicator-border-radius;
}

.knbr-logo {
  position: absolute;
  left: 0;
  top: 90px;
}

.login-btns button.btn {
  padding: $table-cell-padding;
  font-size: $login-font-size;
  font-weight: $headings-font-weight;
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0 !important;
  box-shadow: $box-shadow-0;
}

.login-forms {
  background: $card-bg;
  border-radius: $border-radius;
  padding: $mark-padding;
  float: left;
}

.login-forms .form-group {
  position: relative;
  outline: none;
}

.login-forms .form-group input.form-control,
.head-notes textarea.form-control {
  padding: 20px 14px 20px 14px;
  background: none;
  color: $gray-800;
  outline: none;
  height: auto;
  line-height: normal;
  display: block;
  width: 300px;
  border: none;
  border: 1px solid $gray-500;
  outline: $pagination-focus-outline;
}

.login-forms .form-group select.form-control {
  padding: 20px 14px 20px 14px;
  background: none;
  color: $gray-800;
  height: auto;
  line-height: normal;
  display: block;
  width: 300px;
  border: none;
  border: 1px solid $gray-500;
  -webkit-appearance: none;
}

.login-forms .form-group input:focus {
  outline: none;
}

.login-forms .form-group select:focus {
  outline: none;
}

.login-forms .form-group label {
  color: $gray-600;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.login-forms .form-group label {
  top: -12px;
  left: 14px;
  font-size: 14px;
  color: $gray-800;
  padding: 0px 5px;
  background: var(--cx-color-inverse);
  z-index: 1;
}

// Login Styling end
// Footer Styling starts
.knbr-footerTop .footer-nav ul {
  list-style: none;
}

// Footer Styling end
// Header Styling starts

header .SiteLogo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}

header .SiteLogo cx-media {
  background: var(--cx-color-inverse);
}

header .SiteLogo cx-media img {
  width: 150px;
  height: 40px;
}

header .SiteLogo kn-br-banner {
  flex: initial;
}

header cx-page-layout.header .SearchBox {
  margin: 0 1.5rem;
  // display: flex;
  // align-items: center;
  flex: auto;
  justify-content: left;

  cx-searchbox label.searchbox {
    width: 24vw;
    padding: 2px 10px !important;
  }
}

header .SiteContext:after {
  border-top: none;
}

header .SiteContext {
  flex: auto;
  align-items: center;
  cursor: $custom-range-track-cursor;
  background: none;
  margin-right: 5px;
  justify-content: flex-end;
  position: relative;
}

header .SiteContext span.cart-context .res-cart-context {
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  overflow: hidden;
  max-width: 350px;
}

header .SiteLinks {
  flex: initial;
  align-items: center;
  background: none;
}

header .SiteLogo .top-bar-nav {
  flex: initial;
}

header .header {
  max-width: 100%;
  padding: 0 15px;
  position: fixed;
  z-index: 1000;
  background-color: var(--cx-color-primary);
  min-height: 53px;
}

.breadcrumb .selected-pages ul {
  list-style: none;
}

header .header .SiteLinks cx-generic-link a::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f011';
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  text-decoration: none;
  color: var(--cx-color-inverse);
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 0;
}

cx-storefront.asm-storefront cx-page-layout.header .SiteLinks cx-generic-link a::after {
  right: 0;
}

header .header .SiteLinks cx-generic-link a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  float: right;
  margin: 16px 8px;
}

header .SiteContext cx-site-context-selector label select {
  background-color: var(--cx-color-primary);
  color: var(--cx-color-inverse);
  font-size: 15px;
  cursor: $custom-range-track-cursor;
}

header .SiteContext cx-site-context-selector label cx-icon {
  background-color: var(--cx-color-primary);
  color: var(--cx-color-inverse);
  cursor: $custom-range-track-cursor;
}

.breadcrumb .selected-pages ul li a {
  font-size: $font-size-sm;
  color: $breadcrumb-title;
  font-weight: $font-weight-bold;
  text-decoration: none;
}

.head-breadcrumb {
  z-index: $zindex-modal;
}

.cart-count.MiniCart a {
  background: $cart-color;
  padding: 8px;
  height: auto;
  border-radius: $btn-border-radius;
  width: auto;
  color: var(--cx-color-inverse);
  flex-direction: inherit;
  float: right;
  min-width: auto;
  justify-content: flex-end;

  &:hover {
    color: var(--cx-color-inverse);
  }
}

.cart-count.MiniCart a span.count {
  margin: 0;
}

.cart-count.MiniCart {
  width: 110%;
  float: right;
  text-align: right;
}

.knbr-dropdown {
  border-radius: 0.25rem;
  top: 0px !important;
  z-index: 9999;
  padding: 0;
}

.knbr-dropdown a.dropdown-item:focus,
.knbr-dropdown a.dropdown-item:active,
.knbr-dropdown a.dropdown-item:hover {
  background-color: var(--cx-color-primary);
  color: var(--cx-color-inverse);
}

.breadcrumb {
  // position: relative;
  top: 51px;
  min-height: 58px;
}

.login-forms .form-group .ng-select-container {
  // z-index: 997 !important;
  border: 1px solid $gray-500;
}

// Header Styling end
// Home-page Styling starts
.counters-section {
  background: $counter-color;
  opacity: $tooltip-opacity;
}

.counters-section .counter-icons i {
  font-size: $counter-icon-size;
}

.container-fluid.home-container {
  background: $container-color;
  margin-top: 123px;
}

.customersearch-title {
  color: $breadcrumb-title;
  font-size: $h3-font-size;
  font-weight: bold;
  line-height: 1.15;
}

.login-forms .form-group i.fa.fa-caret-down {
  position: absolute;
  right: 27px;
  top: 9px;
  color: var(--cx-color-secondary);
}

.back-btn {
  background: $gray-300;
  color: $gray-900;
  width: auto;
  max-height: min-content;
  height: auto;
  border-radius: 0.25rem;
}

.customersearch-forms input.form-control {
  font-size: $font-size-base !important;
}

.customersearch-forms select.form-control {
  font-size: $font-size-base !important;
}

.customersearch-list-table .customersearch-list-titles {
  background: $customer-titles;
  color: $gray-600;
  cursor: $custom-range-track-cursor;
  font-size: $font-size-base;
}

.customersearch-list-titles.table-content:hover {
  -webkit-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  -moz-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.customersearch-list-table .customersearch-list-titles .title-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

body .customersearch-forms.login-forms input.form-control,
body .customersearch-forms.login-forms .ng-select-container .ng-input>input {
  padding: 16px 14px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  line-height: normal;

  &::placeholder {
    color: #929292;
    ;
  }
}

body .customersearch-forms.login-forms .ng-select-container .ng-input>input:disabled {
  background: $customer-titles;
  cursor: not-allowed;
}

body .customersearch-forms.login-forms .ng-select.ng-select-disabled>.ng-select-container {
  background-color: $customer-titles;

  &::placeholder {
    color: #74777a;
  }
}

body .customersearch-forms.login-forms input.form-control:disabled {
  background: $customer-titles;
  cursor: not-allowed;
}

.customer-home-forms .ng-select-container {
  z-index: 0;
  padding: 16px 0 16px 5px !important;
  line-height: normal !important;
  color: $filter-color;
  outline: none;
  overflow: initial;
  border: 1px solid $gray-500 !important;
}

body .customersearch-forms.login-forms .form-group.country-form input {
  padding: 9px 7px;
}

// Home-page Styling
// Product-Grid Styling starts
.product-action-section a.grid-icon {
  background: $product-actions;
  color: $gray-700;
  font-size: 19px;
  width: 40px;
  height: 39px;
}

.product-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.add-cart-quote button.btn {
  opacity: 0.8;
}

.select-all {
  right: -42px;
  top: 50%;
  transform: rotate(90deg);
  border-radius: $border-radius-lg;
  z-index: $zindex-tooltip;
}

cx-page-layout.ErrorPageTemplate {
  background: var(--cx-color-inverse);
  padding: 15px;
  width: auto;
  max-width: 100%;
  min-height: calc(100vh - 257px);
  border-radius: $border-radius;
  margin: 90px 15px 15px 15px;
}

cx-page-layout.ErrorPageTemplate {
  kn-br-banner {
    max-width: 400px;
  }

  cx-media {
    justify-content: center;
  }

  cx-page-slot.BottomContent cx-generic-link {
    float: left;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  cx-page-slot.BottomContent cx-generic-link a {
    background: var(--cx-color-primary);
    color: var(--cx-color-inverse);
    padding: 10px 33px;
    float: left;
    border-radius: 4px;
  }
}

cx-page-layout.KnBrProductListPageTemplate,
cx-page-layout.KnBrProductGridPageTemplate,
cx-page-layout.KnBrSearchResultsListPageTemplate,
cx-page-layout.KnBrSearchResultsGridPageTemplate,
cx-page-layout.KnBrDraftOrderPageTemplate,
cx-page-layout.KnBrQuotePageTemplate,
cx-page-layout.MultiStepCheckoutSummaryPageTemplate,
cx-page-layout.KnBrOrderConfirmationPageTemplate,
cx-page-layout.KnBrQuoteDetailPageTemplate,
cx-page-layout.KnBrDraftOrderCreatePageTemplate,
cx-page-layout.KnBrQuoteCreatePageTemplate,
cx-page-layout.KnBrDraftOrderDetailPageTemplate,
cx-page-layout.KnBrLandingPage2Template,
cx-page-layout.KnBrCustomer360PageTemplate,
cx-page-layout.KnBrOrderHistoryPageTemplate,
cx-page-layout.KnBrOrderDetailsPageTemplate,
cx-page-layout.KnBrReturnHistoryPageTemplate,
cx-page-layout.KnBrReturnDetailsPageTemplate,
cx-page-layout.KnBrInvoiceListPageTemplate,
cx-page-layout.KnBrAddressBookPageTemplate,
cx-page-layout.KnBrFactSheetPageTemplate,
cx-page-layout.KnBrOrderImportPageTemplate,
cx-page-layout.KnBrPurchasingAgentPageTemplate,
cx-page-layout.KnBrServiceTicketsPageTemplate,
cx-page-layout.KnBrQuickOrderPageTemplate,
cx-page-layout.KnBrOrderFormsPageTemplate,
cx-page-layout.KnBrQuickEntryPageTemplate,
cx-page-layout.AccountPageTemplate,
cx-page-layout.NACPageTemplate,
cx-page-layout.KnBrMarketCentrePageTemplate,
cx-page-layout.CompanyPageTemplate {
  background: var(--cx-color-inverse) !important;
  padding: 15px !important;
  width: auto;
  max-width: 100% !important;
  border-radius: $border-radius;
  margin: 65px 15px 15px 15px !important;
}

cx-place-order .cx-place-order-form .form-check-label {
  padding: 8px 0;
}

cx-page-layout.ContentPage1Template {
  background: var(--cx-color-inverse);
  padding: 15px 100px;
  width: auto;
  max-width: 100%;
  border-radius: $border-radius;
  margin: 70px 15px 15px 15px;
}

cx-page-layout.KnBrProductListPageTemplate,
cx-page-layout.KnBrProductGridPageTemplate,
cx-page-layout.KnBrSearchResultsListPageTemplate,
cx-page-layout.KnBrSearchResultsGridPageTemplate,
cx-page-layout.KnBrProductDetailsPageTemplate {
  .SearchBox.has-components {
    float: left;
    width: auto;
  }

  .SearchBox.has-components {
    float: left;
    width: auto;
    width: 25%;
  }

  .filter-section .fas.fa-times {
    display: none;
  }

  .product-action-section .ng-select-container {
    height: 39px !important;
  }

  .product-action-section cx-sorting {
    width: 75%;
  }

  .SearchBox.has-components label.searchbox {
    background: var(--cx-color-inverse);
    border: 1px solid $gray-500;
    border-radius: $border-radius;
    padding: 7px;
    width: 100%;
    direction: rtl;

    cx-icon {
      padding-right: 7px;
    }
  }

  .SearchBox.has-components label.searchbox input {
    height: auto;
    float: right;
    text-align: left;
    color: $filter-color;
    direction: ltr;
  }

  .Section1.has-components {
    width: 75%;
    height: auto;
    padding-left: 15px;
  }

  .ProductLeftRefinements {
    width: 25%;
    float: left;
  }

  .product-search-actions i.fa.fa-search {
    left: 20px;
    top: 10px;
  }

  .filter-by .accordion .card-header {
    padding: 0;
  }

  .accordion .collapse a.value {
    display: flex;
    align-items: center;
  }

  .filter-by .accordion .card-body {
    padding: 0;
  }

  .filter-by .accordion .card-header button.btn.btn-link {
    text-decoration: none;
    font-size: $login-font-size;
    font-weight: $font-weight-bold;
    width: 100%;
    text-align: left;
    display: block;
    color: $filter-color;
  }

  .filter-by .accordion .card-header button.btn.btn-link::after {
    text-decoration: none;
    content: '˗';
    text-align: left;
    float: right;
    position: absolute;
    color: $filter-color;
    font-size: 35px;
    top: -4px;
    right: 15px;
  }

  .filter-by .accordion .card-header button.btn.btn-link.collapsed::after {
    text-decoration: none;
    content: '\002B';
    text-align: left;
    float: right;
    position: absolute;
    color: $filter-color;
    font-size: 32px;
    top: 2px;
    right: 13px;
  }

  .filter-by .accordion .collapse {
    min-height: 190px;
    overflow: auto;
  }

  .customersearch-list-table .customersearch-list-titles cx-media {
    width: 20%;
    height: 50px;
    align-items: center;
    justify-content: center;
  }

  .ProductListSlot cx-media,
  .ProductGridSlot cx-media,
  .SearchResultsGridSlot cx-media,
  .SearchResultsListSlot cx-media {
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .selected-pages {
    max-width: 100%;
    background: $customer-titles;
    float: left;
    border-radius: 4px;
    padding: 10px !important;
    margin-bottom: 15px;
  }

  .selected-pages ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .selected-pages ul li {
    float: left;
    width: auto;
  }

  .selected-pages ul li:not(:last-child)::after {
    content: '|';
    position: relative;
    padding: 10px;
    font-weight: $font-weight-bold;
    color: $filter-color;
  }

  .selected-pages ul li a {
    color: $filter-color;
  }

  .customersearch-list-titles.product-list-content:hover {
    -webkit-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    -moz-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }

  .show-more-spinner .loader::before {
    border-top-color: var(--cx-spinner-secondary-color);
    border-bottom-color: var(--cx-spinner-secondary-color);
    border-right-color: var(--cx-spinner-secondary-color);
    border-left-color: var(--cx-color-primary);
  }

  .KnBrProductDetailsPageTemplate cx-page-slot.Summary cx-media {
    width: 100%;
    justify-content: center;
  }
}

.KnBrProductDetailsPageTemplate cx-page-slot.Tabs button.btn {
  background-color: var(--cx-color-primary);
  border: 1px solid var(--cx-color-primary);
}

.KnBrProductListPageTemplate,
.KnBrProductGridPageTemplate,
.KnBrSearchResultsListPageTemplate,
.KnBrSearchResultsGridPageTemplate {
  .product-action-section select.form-control {
    padding: 20px;
    height: 40px;
  }
}

.brandmenu-titles a {
  color: $filter-color;
  font-weight: 600;
  text-transform: capitalize;
}

.brandmenu-titles a:hover {
  color: var(--cx-color-primary);
  font-weight: 600;
}

// Product-Grid Styling ends
// create-cart Styling starts
.valid-to .calendar {
  position: absolute;
  right: 0;
  right: 12px;
  top: 3px;
}

.valid-to .ngb-dp-arrow-btn {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
}

span.res-titles {
  display: none;
}

.menu-contents {
  position: absolute;
  display: none;
  z-index: 10;
  border: 1px solid #ccc;
  height: auto;
  transition: opacity 0.4s;
}

.menu-contents .menu-inner-titles a {
  text-transform: initial;
}

.brand-menu h5.brandmenu-titles:hover .menu-contents {
  display: flex !important;
  max-height: 450px;
  overflow: auto;
  min-height: 260px;
}

.menu-contents .menu-inner-titles:hover .menu-contents-inner {
  display: block !important;
  overflow: auto;
}

.menu-contents-inner {
  position: absolute;
  display: none;
  top: 0;
  transition: opacity 0.4s;
  left: 50%;
  height: 100%;
}

.menu-contents.menu-inner-last {
  left: 280px;
}

.customersearch-list-table .customersearch-list-titles input.form-control {
  padding: 15px;
}

// .customersearch-list-table .customersearch-list-titles .valid-to .calendar {
//   top: 3px;
// }

.modal-content {
  border-radius: $nav-tabs-border-radius;
}

.SiteLogo .top-bar-nav {
  display: none;
}

header .header .SiteLogo .top-bar-nav {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

// Counter Label Css

kn-br-product-list-item {
  kn-br-add-to-cart {
    form {
      display: inline !important;
    }
  }
}

.login-forms .form-group ngb-datepicker.dropdown-menu.show {
  z-index: 10009;
}

.filter-title button.close {
  display: none;
}

.modal-header,
.modal-footer {
  border: none;
}

.customersearch-forms.login-forms.modal-comments-list {
  max-height: 300px;
  overflow: auto;
}

.knbr-quote-reference {
  max-height: 250px;
  overflow: auto;
}

fieldset {
  min-width: 100%;
}

.product-main-grid.quote-ref-modal .alert.alert-warning {
  min-height: auto;
  float: left;
}

.KnBrQuoteActionBarSlot kn-br-quote-attachment-link {
  float: left;
  width: auto;
  flex: auto;
  max-width: 75%;
}

.KnBrDraftOrderActionBarSlot kn-br-draft-order-attachment-link {
  float: left;
  width: auto;
  flex: auto;
  max-width: 75%;
}

body .upload-files {
  label.upload-button {
    min-width: auto;
    padding: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: none;
  }

  .upload-text {
    display: none;
  }

  file-upload.file-upload-section {
    float: left;
  }

  label.upload-button i.fa {
    font-size: 18px;
    max-height: 40px;
  }
}

.upload-files label {
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  max-width: 380px;
  min-height: 43px;
  padding: 10px;
}

.upload-files label a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.upload-files {
  max-width: 82%;
}

.upload-files .btn {
  background: $selected-filter;
}

.d-contents {
  display: contents;
}

.count-currency {
  font-size: 2.25rem;
  color: var(--cx-color-inverse);
  line-height: initial;
}

.KnBrQuoteDetailPageTemplate {
  knbr-kn-br-quote-download-link {
    flex: auto !important;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-width: 13%;
    max-width: max-content;
  }
}

.pending-approved-box {
  border: 1px solid #bbe4e2;
  background: #bbe4e2;
  border-radius: 5px;
  height: 30px;
  text-align: center;
  /* width: 50%; */
  display: inline-block;
}

.KnBrDraftOrderDetailPageTemplate {
  knbr-kn-br-draft-order-download-link {
    flex: auto !important;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-width: 13%;
    max-width: max-content;
  }
}

.SearchBox cx-searchbox {
  z-index: 10;
}

.SearchBox cx-searchbox .results {
  z-index: 0;

  .products {
    a {
      .price {
        margin-top: 0.5rem;
      }
    }
  }
}

header cx-page-layout.header .SearchBox cx-searchbox .results {
  overflow-y: auto;
  max-height: 80vh;
}

main .SearchBox cx-searchbox .results {
  overflow-y: auto;
  max-height: 60vh;
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: inherit !important;
}


.bg-light-gray {
  background: $customer-titles;
}

.doc-viewer-modal-header .mr-left-auto {
  margin-left: auto;
}

.doc-viewer {
  width: 100%;
  height: 93vh;
}

.doc-viewer-modal-header .margin-zero {
  margin: 0px 0px 0px 0px;
}

.dropdown .dropdown-menu:before {
  content: ' ';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid var(--cx-color-primary);
  position: absolute;
  top: -13px;
  left: -58px;
}

.dropdown .dropdown-menu:before {
  left: 82% !important;
}

.KnBrServiceTicketsPageTemplate file-upload .upload-text {
  display: none;
}

.KnBrServiceTicketsPageTemplate file-upload {
  border: none;
}

.KnBrServiceTicketsPageTemplate file-upload .upload-button {
  border: none;
  padding: 0;
}

cx-pagination a {
  width: 44px;
  height: 42px;
}

cx-page-layout.KnBrRegistrationPageTemplate {
  background: var(--cx-color-inverse);
  padding: 15px;
  width: auto;
  max-width: 100%;

  .SideContent kn-br-paragraph p {
    color: $text-muted;
  }

  .SideContent kn-br-paragraph b {
    color: $filter-color;
    font-size: 18px;
    font-weight: 500;
  }
}

.NACPageTemplate file-upload .upload-text {
  display: none;
}

.NACPageTemplate file-upload {
  border: none;
}

.NACPageTemplate file-upload .upload-button {
  border: none;
  padding: 0;
}

.uploadTemplate file-upload .upload-text {
  display: none;
}

.uploadTemplate file-upload {
  border: none;
}

.uploadTemplate file-upload .upload-button {
  border: none;
  padding: 0;
}

.modal .close {
  align-self: auto;
}

.quick-link:hover {
  -webkit-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  -moz-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

cx-page-layout.KnBrMarketCentrePageTemplate {
  knbr-quick-links {
    flex: 1 1 var(--cx-flex-basis, 50%) !important;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.btn-link {
  color: var(--cx-color-primary);

  &:hover {
    color: var(--cx-color-primary);
  }
}

body.searchbox-is-active.has-searchbox-results cx-searchbox .searchbox.dirty+.results {
  display: block;
}

body.searchbox-is-active.has-searchbox-results cx-searchbox .searchbox+.results {
  display: none;
}

cx-searchbox .searchbox.dirty cx-icon.reset {
  display: block !important;
}

cx-searchbox .searchbox cx-icon.reset {
  display: none !important;
}

cx-searchbox .searchbox.dirty cx-icon.search {
  display: none !important;
}

cx-searchbox .searchbox cx-icon.search {
  display: block !important;
}

// User Management CSS Start

:root {
  --cx-split-gutter: 20px;
}

cx-page-layout.CompanyPageTemplate {
  .BodyContent {
    margin: 0;
  }

  cx-org-list cx-split-view {
    padding: 10px 0;
  }

  cx-org-list cx-table thead th {
    height: auto;
    text-transform: capitalize;
    font-size: 1rem;
  }

  cx-org-list cx-table td a {
    text-decoration: none !important;
    min-height: auto;
    padding: 0;
    color: #000;
  }

  cx-org-list cx-table td a {
    ul {
      list-style: none;
    }
  }

  cx-org-list cx-table.vertical td {
    height: auto;
  }

  cx-org-list .header .title {
    button {
      display: none;
    }
  }

  cx-org-list .list .header {
    padding: 0 0 15px 0;
    height: 115px;
  }

  cx-org-list.orgUser .list .header .actions {
    display: flex;
    justify-content: flex-end;

    label {
      display: none;
    }
  }

  cx-org-list .list .header .actions a,
  cx-org-list .list .header .actions button {
    text-transform: capitalize;
  }

  cx-org-list .list .header h3 {
    margin: 0 0 15px 0;
    text-transform: capitalize;
  }

  cx-org-list .card .header {
    height: 115px;
    padding: 0 30px;
  }

  cx-org-list .card .header .actions a,
  cx-org-list .card .header .actions button {
    text-transform: capitalize;
  }

  cx-org-list .card .header h3 {
    text-transform: capitalize;
  }

  cx-org-list .card .header h4 {
    margin: 0;
  }

  cx-org-list .card section {
    margin: 20px;
  }

  cx-org-list .card section.details {
    padding: 20px 0px 20px 20px;

    label .label-content {
      text-transform: capitalize;
    }
  }

  cx-org-list .card section.details .property {
    margin-bottom: 20px;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 9px;
  }

  cx-table table thead {
    border-top: solid 1px var(--cx-color-light) !important;
  }

  cx-table table>tr {
    border-bottom: solid 1px var(--cx-color-light) !important;
  }
}

// User Management CSS End

.KnBrLoginPageTemplate cx-asm-main-ui~main cx-page-layout.KnBrLoginPageTemplate .SiteLogo {
  display: none;
}

.KnBrLoginPageTemplate cx-asm-main-ui.hidden~main cx-page-layout.KnBrLoginPageTemplate .SiteLogo {
  display: flex;
}

.KnBrLoginPageTemplate cx-asm-main-ui+header {
  display: none;
}

.highlight-menu {
  background: $gray-300;
}

table thead th {
  text-transform: capitalize !important;
}

cx-storefront.asm-storefront cx-page-layout.header,
cx-storefront.asm-storefront .breadcrumb {
  position: static;
}

cx-storefront.asm-storefront main cx-page-layout {
  margin: 15px 15px;
}

cx-storefront.asm-storefront main cx-page-layout.KnBrLandingPage2Template {
  margin: 0;
}

.LandingPage2Template cx-page-slot.Section2.has-components {
  background: var(--cx-color-inverse);
  margin-top: 15px;
  padding: 15px;
  border-radius: 4px;

  knbr-recent-customer-result {
    flex: 1 1 var(--cx-flex-basis, 50%) !important;
    padding-right: 10px;
    margin-top: 15px;
  }

  knbr-quick-links {
    flex: 1 1 var(--cx-flex-basis, 50%) !important;
    padding-left: 10px;
    margin-top: 15px;
  }
}

knbr-kn-br-reset-password.user-form {
  position: static;
}

.min-height {
  min-height: 0px !important;
}

ngb-modal-backdrop {
  z-index: 999 !important;
}

.btn,
button {
  padding: 13px 12px;
}

.bg-primary {
  background-color: #fe5757 !important;
}
