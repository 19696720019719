cx-page-layout.KnBrLandingPage2Template {
  display: flex;
  flex-wrap: wrap;

  cx-page-slot {
    kn-br-banner {
      cx-media {
        img {
          display: flex;
          height: auto;
        }
      }
    }

    &.Section1 {
      max-width: 100%;
      margin: auto;

      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }

    &.Section2A {
      margin-inline-start: auto;
    }

    &.Section2B {
      margin-inline-end: auto;
    }

    &.Section2A,
    &.Section2B {
      --cx-flex-basis: 50%;

      kn-br-banner cx-media img {
        width: 100%;
        padding: 10px 0px;
      }

      @include media-breakpoint-down(md) {
        --cx-flex-basis: 100%;
        max-width: 100%;

        >* {
          --cx-flex-basis: 50%;
        }
      }

      @include media-breakpoint-only(xs) {
        >* {
          --cx-flex-basis: 100%;
        }
      }
    }

    &.Section3 {
      max-width: var(--cx-page-width-max);
      margin: auto;
    }

    &.Section4 {
      color: var(--cx-color, var(--cx-color-light));
      flex-wrap: nowrap;

      kn-br-banner cx-media img {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;

        >* {
          --cx-flex-basis: 50%;
          border-bottom: solid 1px currentColor;
          border-inline-end: solid 1px currentColor;
        }
      }

      @include media-breakpoint-only(xs) {
        >* {
          --cx-flex-basis: 100%;
        }
      }
    }
  }

  a.kn-br-product-carousel cx-media {
    &.is-missing {
      min-height: 175px !important;
    }

    img {
      height: 175px;
    }
  }

  a.kn-br-product-carousel:hover {
    text-decoration: none;
  }

  cx-carousel .indicators button {
    background-color: transparent;
  }

  cx-carousel .indicators button[disabled] .cx-icon {
    background-color: var(--cx-color-primary);
    padding: 0px;
  }

  cx-carousel .indicators button .cx-icon {
    border: none;
  }
}
