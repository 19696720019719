cx-page-layout.AccountPageTemplate cx-page-slot>* {
  max-width: 50% !important;
}

.user-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    width: 50%;
    --cx-max-width: 50%;
  }

  position: relative;

  form {
    display: contents;

    label {
      all: unset;
      flex: 100%;
      margin-bottom: 0.375rem;
    }
  }

  button,
  a {
    flex: 0 0 calc(50% - 10px);
    margin-top: 0.5rem;
    border-radius: 0.25rem;
  }
}
