:root {
  --cx-color-primary: #0067b2;
  --cx-color-secondary: #929292;
  --cx-color-inverse: #ffffff;
}

$login-font-size: 1.15rem;
$counter-icon-size: 2.25rem;
$font-weight-bold: 600;
$header-icons: 1.55rem;
$knbr-footer: #363636;
$breadcrumb-title: #5e5e5e;
$counter-color: #0067b2;
$container-color: #efefef;
$customer-titles: #f0f0f0;
$product-actions: #e0e0e0;
$filter-color: #516269;
$box-shadow-0: none;
$checkbox-border: #787979;
$cart-color: #0067b2;
$selected-filter: #f0f0f0;
$counter-font: 30px;
$alart-success: #d4edda;
$success-color: #155724;
$common-border: #dee2e6;
$search-grey-100: #8b9196;
$text-muted: #6c757d;
$checkout-progress-disabled: #9d9d9d;
$yellow-color: #10b230;
$purple-color: #7460ee;
$black-color: #000;
$other-border: #d3d5d6;
$disabled-color: #7d8186;

$primarycolorList: 'knbr-primary' var(--cx-color-primary), 'knbr-secondary' var(--cx-color-secondary),
  'grey-100' #8b9196, 'gray-700' #495057, 'knbr-footer'$knbr-footer, 'light-bg' #f4f4f4, 'lighter-bg' #f7f7f7,
  'white-bg' var(--cx-color-inverse);

@each $i in $primarycolorList {
  .color-#{nth($i,1)} {
    background-color: nth($i, 2);
    border-radius: 0.25rem;
  }
}

$textcolorList: 'knbr-primary' var(--cx-color-primary), 'knbr-secondary' var(--cx-color-secondary);

@each $i in $textcolorList {
  .font-color-#{nth($i,1)} {
    color: nth($i, 2);
  }
}

$fontweightList: 'text-bold' bold, 'text-normal' normal, 'text-400' 400, 'text-500' 500, 'text-600' 600;

@each $i in $fontweightList {
  .font-#{nth($i,1)} {
    font-weight: nth($i, 2);
  }
}

$fontsizeList: 'size' 15px, 'lg-size' 21px, 'size-17' 17px, 'sm-size' 14px, 'xl-size' 25px, 'size-18' 18px,
  'size-24' 24px;

@each $i in $fontsizeList {
  .font-#{nth($i,1)} {
    font-size: nth($i, 2);
  }
}

$lineHeightList: '14' 14px, '15' 15px, '16' 16px, '18' 18px, '20' 20px;

@each $i in $lineHeightList {
  .line-height-#{nth($i,1)} {
    line-height: nth($i, 2);
  }
}

$whitecolorList: 'white' var(--cx-color-inverse), 'font' #516269;

@each $i in $whitecolorList {
  .color-#{nth($i,1)} {
    color: nth($i, 2);
  }
}

.counters-section h4 {
  color: var(--cx-color-inverse);
  font-size: 1rem;
}

.counters-section h1 {
  color: var(--cx-color-inverse);
  font-size: $h3-font-size;
}
