body {
  padding: 0;
  margin: 0;
  font-family: Gotham Narrow A, Gotham Narrow B, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  outline: none !important;
}

sloan-footer cx-generic-link a {
  color: var(--cx-color-secondary);
  font-size: 14px;
  font-weight: normal;

  &:hover {
    color: var(--cx-color-secondary);
  }
}

cx-storefront.stop-navigating {
  &:after {
    background-color: var(--cx-color-inverse);
  }
}

cx-storefront.stop-navigating {
  &:before {
    background-color: $black-color;
  }
}

header .header {
  color: var(--cx-color-primary);
  background-color: $knbr-header;
  border-bottom: 1px solid $other-border;

  cx-site-context-selector {
    display: none;
  }

  .language {
    display: none;
  }
}

header .header .SiteLogo .top-bar-nav a {
  color: $black-color;
}

header .header .SiteContext .customer-context {
  color: $black-color;
}

header .header .SiteContext .user-span {
  color: $black-color;
}

header .header .SiteContext .language .fa-globe {
  color: $black-color;
}

header .header .SiteContext .fa-building {
  color: $black-color;
}

header .SiteContext .signup-link {
  color: $black-color;
  border: 1px solid $black-color;
  padding: 3px 15px;
  border-radius: $btn-border-radius;
}

header .SiteContext .signin-link {
  color: $black-color;
  border: 1px solid $black-color;
  padding: 3px 15px;
  border-radius: $btn-border-radius;
}

header .SiteContext cx-site-context-selector label select {
  color: $black-color;
  background-color: $knbr-header;
}

header .SiteContext cx-site-context-selector label cx-icon {
  color: $black-color;
  background-color: $knbr-header;
}

header .header .SiteLinks cx-generic-link a::after {
  color: $black-color;
  top: 8px;
}

header .SearchBox {
  cx-searchbox>* {
    color: $black-color;
    background-color: $knbr-header;
  }

  label.searchbox {
    border: 1px solid $other-border;
  }
}

.breadcrumb .selected-pages ul li a {
  font-size: 1rem;
}

.cart-count.MiniCart a {
  background: var(--cx-color-primary);
}

.counters-section {
  opacity: 1;
}

.customersearch-title {
  font-size: $h3-font-size;
  font-weight: 500;
}

.filter-by .accordion .card {
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 0px;

  .card-header {
    padding: 0;
    background-color: var(--cx-color-inverse);
  }
}

.filter-by .accordion .card-header button.btn.btn-link {
  padding: 11px 5px 12px;
  font-weight: 400 !important;
}

.filter-by .accordion .collapse {
  min-height: auto !important;
  padding-bottom: 15px;
}

.product-cate-options {
  overflow: auto;
  max-height: 100vh;
}

.product-image {
  cx-media {
    text-align: center;
  }
}

.login-btns button.btn {
  font-size: 1rem;
}

sloan-footer .footer-copyright {
  color: #929292;
  font-size: 14px;
  font-weight: normal;
}

sloan-footer .footer-nav {
  margin-right: 20px;
}

sloan-footer .FooterLogo cx-media {
  padding: 3px 10px;

  img {
    width: 130px;
    height: auto;
  }
}

sloan-footer .FooterSocialMedia {
  justify-content: flex-end;

  kn-br-banner {
    flex: 0 0 9%;
  }

  cx-media img {
    width: 20px;
    height: auto;
  }
}

.btn-primary {
  &:disabled {
    color: var(--cx-color-inverse);
    background-color: $disabled-color;
    border-color: $disabled-color;
    border-radius: 0.25rem;
  }
}

.btn-outline-primary {
  &:disabled {
    color: var(--cx-color-inverse);
    background-color: $disabled-color;
    border-color: $disabled-color;
  }
}

.btn-action {
  &:disabled {
    color: var(--cx-color-inverse);
    background-color: $disabled-color;
    border-color: $disabled-color;
  }
}

.btn-secondary {
  font-size: 1rem;
  height: auto;
  color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  background-color: var(--cx-color-inverse);
  border-radius: 0.25rem;

  &:hover {
    color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
    filter: none;
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
    filter: none;
  }

  &.disabled {
    color: var(--cx-color-light);
    border-color: var(--cx-color-light);
    opacity: 1;
    filter: none;
  }
}

cx-page-layout.CompanyPageTemplate {
  cx-org-list cx-table .active span.is-active {
    color: $sloan-green;
  }
}

sloan-address-form .address-form .ng-select-container {
  font-size: 15px;
  margin-bottom: 0;
  height: auto !important;
  line-height: 1.5 !important;
}

kn-br-product-intro .code {
  font-size: 18px;
}

kn-br-product-intro .summary {
  font-size: 22px;
  font-weight: 300;
}

kn-br-product-intro .upc {
  font-size: 18px;
  font-weight: 300;
}

kn-br-product-summary .price label,
kn-br-product-intro .code label,
kn-br-add-to-cart .quantity label {
  font-weight: 500;
}

cx-page-layout.KnBrProductListPageTemplate,
cx-page-layout.KnBrProductGridPageTemplate,
cx-page-layout.KnBrSearchResultsListPageTemplate,
cx-page-layout.KnBrSearchResultsGridPageTemplate,
cx-page-layout.KnBrProductDetailsPageTemplate {

  .ProductListSlot cx-media,
  .ProductGridSlot cx-media,
  .SearchResultsGridSlot cx-media,
  .SearchResultsListSlot cx-media {
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.MultiStepCheckoutSummaryPageTemplate {
  .cx-review-card-shipping {
    .cx-card-paragraph {
      padding-left: 0;
    }

    .cx-card-paragraph-title {
      font-style: normal !important;
      font-size: 1rem;
      font-weight: 500;
      padding-top: 0.5em;
    }
  }

  cx-review-submit {
    .cx-review-cart-item {
      cx-cart-item-list {
        .cx-item-list-row {
          border-bottom: 1px solid #516269;

          .cx-item-list-items {
            cx-cart-item {
              .cx-name {
                font-size: var(--cx-font-size, 1rem);
                font-weight: var(--cx-font-weight-bold);
                line-height: var(--cx-line-height, 1.2222222222);
                overflow-wrap: break-word;
                padding: 0;

                .cx-link {
                  color: var(--cx-color-text);
                  text-decoration: none;

                  &:hover {
                    color: var(--cx-color-primary)
                  }

                }
              }

              .cx-info {
                &-container {
                  .cx-price {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .cx-quantity {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .cx-total {
                    display: flex;
                    justify-content: end;
                    align-items: center;

                  }

                  .cx-code,
                  .cx-name {
                    margin-top: 0 !important;
                    line-height: var(--cx-line-height, 1.2222222222) !important;
                  }
                }
              }
            }
          }
        }

        .cx-item-list-header {
          .cx-item-list-desc {
            text-align: start;

          }

          .cx-item-list-total {
            text-align: end;
          }
        }
      }
    }
  }

  cx-checkout-order-summary {
    cx-order-summary {
      display: block !important;

      h4 {}

      .cx-summary-partials {
        .cx-summary-row {
          .cx-review-summary-card {
            cx-card {
              .cx-card {
                &-title {
                  font-size: 1.125rem !important;
                  font-weight: var(--cx-font-weight-bold) !important;
                }
              }
            }

          }

          .cx-review-card-shipping {
            .delivery-details {
              font-size: 1.125rem !important;
              font-weight: var(--cx-font-weight-bold) !important;
            }

            .delivery-mode,
            .delivery-notes,
            .delivery-account {
              font-weight: var(--cx-font-weight-bold) !important;
            }
          }
        }
      }
    }
  }

  cx-order-summary .cx-summary-row h4.cx-card-title {
    font-size: 1.125rem !important;
  }

  cx-order-summary .cx-paymentType {
    font-size: 1.125rem;
    font-weight: var(--cx-font-weight-bold);
  }

  cx-order-summary .cx-summary-total {
    font-size: 22px;
    font-weight: var(--cx-font-weight-bold);
  }
}

cx-page-layout.KnBrLandingPage2Template {
  background: var(--cx-color-inverse);
  padding: 0px 0px 15px 0px;
  width: auto;
  max-width: 100%;
  border-radius: $border-radius;
  margin: 52px 0px 15px 0px;

  .Section3 {
    max-width: 94% !important;
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 10px;
    left: 15%;
    z-index: 10;
    color: #fff;
    text-align: center;
    top: 20%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);

    .main-heading {
      color: $white;
      font-size: 36px;
      font-weight: 500;
      line-height: 43px;
      margin-bottom: 20px;
    }

    .sub-heading {
      color: $white;
      font-size: 24px;
      font-weight: 300;
      line-height: 34px;
      margin-bottom: 20px;
    }
  }

  .category-text {
    margin-top: 10px;
    color: #333333;
    line-height: normal;
    font-weight: normal;
  }

  cx-page-slot.Section2A kn-br-banner cx-media img,
  cx-page-slot.Section2B kn-br-banner cx-media img {
    padding: 10px 10px;
  }

  cx-page-slot.Section3 cx-carousel h3 {
    font-size: 48px;
    line-height: 58px;
    color: #4a4a4a;
    font-weight: normal;
    margin: 3rem 0px;
    text-align: center;
  }

  cx-page-slot.Section1B kn-br-paragraph p {
    font-size: 48px;
    line-height: 58px;
    color: #4a4a4a;
    font-weight: normal;
    margin-top: 4rem;
    margin-bottom: 3rem;
    text-align: center;
  }
}

.btn-success {
  font-size: 1rem;
  height: 45px;
  color: var(--cx-color-inverse);
  border-color: #7ac254;
  background-color: #7ac254;
  min-width: 200px;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-shadow: none;
  line-height: 24px;
  padding: 10px 25px;
  margin: 10px 0;
  border-radius: 0;
  border: 1px solid transparent;

  &:hover {
    color: var(--cx-color-inverse);
    border-color: #7ac254;
    background-color: #7ac254;
  }

  &:focus {
    color: var(--cx-color-inverse);
    border-color: #7ac254;
    background-color: #7ac254;
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-inverse);
    border-color: #7ac254;
    background-color: #7ac254;
  }
}

.KnBrOrderConfirmationPageTemplate {
  cx-order-confirmation-overview {
    cx-order-overview {
      .card-body {
        padding: 12px 15px;
      }
    }
  }
}

.MultiStepCheckoutSummaryPageTemplate,
.KnBrOrderConfirmationPageTemplate {
  cx-cart-item .cx-code {
    font-size: 1rem;
    color: $text-muted;
    padding: 0.625rem 0 0 0;
  }
}

cx-page-layout.KnBrProductDetailsPageTemplate cx-product-attributes,
cx-page-layout.KnBrProductDetailsPageTemplate cx-product-technical-documents {
  table ul li p {
    font-weight: 500;
    margin: 0 0 15px 0;
  }

  table ul.show-bulleted-list {
    list-style: disc;
  }

  table ul.show-bulleted-list li {
    margin-left: 20px;
  }

  table ul.show-bulleted-list li:first-child p {
    list-style-type: none;
    margin-left: 0px;
  }
}

cx-page-layout.KnBrDraftOrderDetailPageTemplate,
cx-page-layout.KnBrQuoteDetailPageTemplate {

  .KnBrDraftOrderHeaderSlot,
  .KnBrQuoteHeaderSlot {
    margin-top: 0px;
  }

  .alert.alert-dismissible {
    padding-left: 0px;
    min-height: 60px;
  }

  .alert .close {
    right: 0;
    top: 0;
    font-size: 2rem;
    padding: 14px 1.25rem;
  }
}

kn-br-quote-item .alert,
kn-br-draft-order-item .alert {
  min-height: 40px;
}

#iframewrapper iframe {
  border: none;
}

.text-limit {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
}

.KnBrLoginPageTemplate cx-asm-main-ui~main cx-page-layout.KnBrLoginPageTemplate .SiteLogo {
  display: block;
}

cx-product-references {
  display: none;
}

.alert.alert-warning {
  min-height: 55px;
}

kn-br-product-images {
  >cx-media {
    img {
      object-fit: contain;
      padding-top: 25px;
      padding-left: 5px;
    }
  }
}

sloan-forgot-password ngb-alert p {
  margin-bottom: 0;
}
