@media not print {
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .m-res-top {
      margin-top: 15px !important;
    }

    .p-res-top {
      padding-top: 15px !important;
    }

    .p-res-right {
      padding-right: 15px !important;
    }

    .p-res-0 {
      padding: 0 !important;
    }

    .m-res-0 {
      margin: 0 !important;
    }

    .m-res-bottom {
      margin-bottom: 15px !important;
    }

    .gcp-logo img.login-logo {
      width: 54%;
    }

    .login-wrapper {
      padding-bottom: 20px !important;
      padding-top: 20px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
      top: 215px;
    }

    .KnBrLoginPageTemplate .SiteTitle.has-components p {
      padding: 0px 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .footer-social-media ul {
      justify-content: start !important;
    }

    span.res-titles {
      display: block !important;
      float: left;
      width: auto;
      font-weight: 700;
      margin-right: 8px;
    }

    .customersearch-list-table .customersearch-list-titles .title-wrap {
      display: block;
      width: 100%;
      padding: 10px;
    }

    .bg-white.customersearch-list-titles.table-content {
      display: block !important;
      margin-bottom: 15px;
      border-radius: 4px;
      border: 1px solid $common-border;
    }

    cx-storefront.stop-navigating {
      min-height: 146vh;
    }

    header .SiteLogo {
      flex: auto;
    }

    header .SiteLogo cx-media img {
      width: 100px;
      height: 30px;
    }

    header cx-site-context-selector {
      padding: 0 !important;
      border: none !important;
      color: var(--cx-color-inverse);
    }

    .language {
      display: flex;
      height: 100%;
      position: relative;
      left: 12px;
    }

    .language span {
      padding: 13px 0px 13px 0px !important;
    }

    header .SiteContext {
      display: flex;
      width: 198px;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
    }

    .logout-btn.top-bar-nav.res-user span.user-name {
      display: none;
    }

    .customer-context .res-cart-context {
      display: block;
      max-width: 95px !important;
    }

    header .SiteContext cx-site-context-selector label select {
      width: 25px;
      opacity: 0;
    }

    header .SiteLinks {
      padding: 7px;
    }

    span.user-span {
      padding-left: 8px !important;
    }

    .kn-br-sidebar aside {
      width: 56% !important;
    }

    .KnBrProductListPageTemplate,
    .KnBrProductGridPageTemplate,
    .KnBrSearchResultsListPageTemplate,
    .KnBrSearchResultsGridPageTemplate,
    .KnBrProductDetailsPageTemplate {
      .SearchBox.has-components {
        float: left;
        width: auto;
      }

      .Section1.has-components {
        width: 100%;
        height: auto;
      }

      .product-action-section cx-sorting {
        width: 84%;
      }

      .product-action-section {
        display: block !important;
      }

      .product-action-section .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        text-align: start;
      }

      .product-action-section .form-group {
        width: 100% !important;
      }

      .product-action-section .res-actions {
        float: right;
        text-align: right;
        display: flex;
        justify-content: flex-end;
      }

      .ProductLeftRefinements {
        width: 100%;
        float: left;
      }

      .ProductLeftRefinements button.knbr-filter-btn {
        height: auto;
        width: auto;
        font-size: 15px;
        padding: 8px;
        color: var(--cx-color-primary);
        border-color: var(--cx-color-primary);
        position: absolute;
        top: -70px;
        width: 100%;
      }

      .filter-section .fas.fa-times {
        display: block;
        color: var(--cx-color-inverse);
      }

      .product-cate-options {
        overflow: auto;
        max-height: 610px;
        height: auto;
      }

      .ProductListSlot {
        width: 100%;
      }

      kn-br-facet-list.dialog {
        z-index: 9999;
      }

      kn-br-product-intro .summary {
        width: 86%;
      }

      kn-br-add-to-cart {
        margin: 0 !important;
      }

      kn-br-add-to-quote {
        margin: 0 !important;
      }

      kn-br-add-to-cart span.res-cart-quote.res-w-100 {
        width: 100%;
        float: left;
      }

      kn-br-add-to-cart span.res-cart-quote.res-w-50,
      kn-br-add-to-quote span.res-cart-quote.res-w-50 {
        width: 50%;
      }

      .res-cart-quote {
        background: $cart-color;
        float: left;
        width: 100%;
        border-left: 1px solid var(--cx-color-inverse);
        text-align: center;
        color: var(--cx-color-inverse);
        padding: 9px;
      }

      .res-cart-quote-btns span.res-cart-quote.res-w-100 {
        width: 100%;
      }
    }

    .MultiStepCheckoutSummaryPageTemplate cx-shipping-address {
      background: var(--cx-color-inverse);
    }

    .MultiStepCheckoutSummaryPageTemplate {
      cx-delivery-mode {
        background: var(--cx-color-inverse);
      }

      cx-page-slot.BodyContent .cx-checkout-btns .col-sm-12.col-md-12.col-lg-6 {
        padding-right: 0;
      }

      cx-shipping-address .cx-shipping-address-card {
        padding: 0 0 15px 0;
      }

      cx-shipping-address .cx-checkout-body.row {
        padding: 0 15px;
      }

      cx-page-slot.BodyContent .cx-checkout-btns {
        padding-bottom: 0;
      }

      cx-page-slot.SideContent cx-checkout-order-summary cx-order-summary {
        padding: 1rem 0 0 0 !important;
      }

      cx-page-slot.SideContent {
        border: none;
      }

      cx-page-slot.BodyContent .cx-checkout-btns .col-md-12.col-lg-6 {
        padding: 0;
      }

      cx-page-slot.BodyContent {
        border: none;
        padding: 0;
      }

      .cx-review-cart-item .cx-item-list-row {
        border: 1px solid $gray-500;
        border-radius: 4px;
        margin-bottom: 15px;
        float: left;
      }

      kn-br-review-submit h4.cx-review-cart-heading {
        display: none !important;
      }

      .cx-review-cart-item .cx-item-list-row .row {
        display: block;
        width: 100%;
        margin: 0;
      }

      cx-cart-item .cx-image-container {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .cx-item-list-items .cx-info {
        max-width: 100%;
        padding: 0;
        margin: 0;
      }

      .cx-item-list-items {
        padding: 0;
      }

      .cx-item-list-items .cx-name {
        text-align: center;
        margin: 15px 0 0;
        float: left;

        width: 100%;
      }

      .cx-item-list-items .cx-name .cx-link {
        color: $filter-color;
      }

      cx-cart-item .cx-price {
        max-width: 100%;
        text-align: center;
        margin: 15px 0 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items cx-cart-item .cx-quantity {
        max-width: 100%;
        text-align: center;
        margin: 15px 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items cx-cart-item .cx-total {
        max-width: 100%;
        text-align: center;
        margin: 15px 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items .cx-code {
        text-align: center;
        padding: 0.625rem 0;
        color: $filter-color;
        float: left;
        width: 100%;
      }

      cx-cart-item-list .cx-image-container cx-media img {
        height: 115px;
      }
    }

    .KnBrOrderConfirmationPageTemplate {
      cx-order-confirmation-totals {
        flex: 0 0 100%;
      }

      cx-order-confirmation-items {
        flex: 0 0 100%;
        border: none;
        padding: 0;
      }

      .cx-item-list-row {
        border: 1px solid $gray-500;
        border-radius: 4px;
        margin-bottom: 15px;
        float: left;
      }

      .cx-review-cart-item .cx-item-list-row .row {
        display: block;
        width: 100%;
        margin: 0;
      }

      cx-cart-item .cx-image-container {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .cx-item-list-items .cx-info {
        max-width: 100%;
        padding: 0;
        flex: 0 0 100%;
        margin: 0;
      }

      .cx-item-list-items {
        padding: 0;
      }

      .cx-item-list-items .cx-name {
        text-align: center;
        margin: 15px 0 0;
        float: left;

        width: 100%;
      }

      .cx-item-list-items .cx-name .cx-link {
        color: $filter-color;
      }

      cx-cart-item .cx-price {
        max-width: 100%;
        text-align: center;
        margin: 15px 0 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items cx-cart-item .cx-quantity {
        max-width: 100%;
        text-align: center;
        margin: 15px 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items cx-cart-item .cx-total {
        max-width: 100%;
        text-align: center;
        margin: 15px 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items .cx-code {
        text-align: center;
        padding: 0.625rem 0;
        color: $filter-color;
        float: left;
        width: 100%;
      }

      cx-cart-item-list .cx-image-container cx-media img {
        height: 115px;
      }
    }

    .modal-dialog {
      margin: 0 auto !important;
      min-width: 94% !important;
      max-width: 94% !important;
    }

    .modal-dialog-centered {
      align-items: center !important;
    }

    .modal-dialog a.btn {
      margin-left: 0 !important;
    }

    .customersearch-list-titles.product-res-titles {
      display: none;
    }

    .KnBrProductListPageTemplate
      kn-br-product-list-item.res-product-grid
      .customersearch-list-titles.product-list-content {
      display: block !important;
      text-align: center;
      border: 1px solid $common-border;
      border-radius: 4px;
      margin-bottom: 15px;
      padding: 0.5rem 0 0 !important;
    }

    .KnBrProductListPageTemplate kn-br-product-list-item.res-product-grid {
      float: left;
      width: 100%;
    }

    .KnBrProductListPageTemplate kn-br-product-list-item.res-product-grid .product-list-img {
      display: block !important;
    }

    .KnBrProductListPageTemplate kn-br-product-list-item.res-product-grid .customersearch-list-titles cx-media {
      width: 100%;
      height: 80px;
      margin-bottom: 15px;
    }

    .filter-title button.close {
      display: block;
      color: var(--cx-color-inverse);
      opacity: 1;
      font-weight: normal;
    }

    .menu-contents {
      position: relative;
      left: 0;
      border: none;
      overflow: hidden !important;
    }
    .menu-inner-titles:hover {
      background: var(--cx-color-inverse);
      display: block !important;
      float: left;
    }
    .menu-inner-titles {
      float: left;
      width: 100%;
      text-align: center;
      display: block !important;
      justify-content: center;
    }
    .res-brand-menu-list {
      float: left;
      width: 100%;
      overflow: auto;
      max-height: 350px;
    }
    .menu-inner-titles i.fa.fa-chevron-right {
      float: left !important;
      padding: 0px;
      width: 100%;
      transform: rotate(90deg);
    }
    .menu-inner-titles span {
      width: 100%;
      text-align: center !important;
    }
    .menu-contents .menu-inner-titles:hover .menu-contents-inner {
      display: block !important;
      overflow: auto;
      position: relative;
      left: 0;
    }

    .breadcrumb {
      display: block !important;
      padding: 0px 0px 10px !important;
      top: 90px;
    }
    .cart-count.MiniCart a {
      width: 100%;
      text-align: center;
    }
    .res-cart-count {
      padding: 10px 10px 0 !important;
      border-top: 1px solid $gray-500;
      float: left;
    }
    .breadcrumb .res-selected-pages .selected-pages ul li a {
      font-size: 15px;
    }
    .res-selected-pages {
      padding: 10px 10px !important;
    }
    .res-brands-btn {
      padding: 10px 10px !important;
    }
    cx-page-layout.KnBrProductListPageTemplate,
    cx-page-layout.KnBrProductGridPageTemplate,
    cx-page-layout.KnBrSearchResultsListPageTemplate,
    cx-page-layout.KnBrSearchResultsGridPageTemplate,
    cx-page-layout.KnBrDraftOrderPageTemplate,
    cx-page-layout.KnBrQuotePageTemplate,
    cx-page-layout.MultiStepCheckoutSummaryPageTemplate,
    cx-page-layout.KnBrOrderConfirmationPageTemplate,
    cx-page-layout.KnBrQuoteDetailPageTemplate,
    cx-page-layout.KnBrDraftOrderCreatePageTemplate,
    cx-page-layout.KnBrQuoteCreatePageTemplate,
    cx-page-layout.KnBrDraftOrderDetailPageTemplate,
    cx-page-layout.KnBrLandingPage2Template,
    cx-page-layout.KnBrCustomer360PageTemplate,
    cx-page-layout.KnBrOrderHistoryPageTemplate,
    cx-page-layout.KnBrOrderDetailsPageTemplate,
    cx-page-layout.KnBrReturnHistoryPageTemplate,
    cx-page-layout.KnBrReturnDetailsPageTemplate,
    cx-page-layout.KnBrInvoiceListPageTemplate,
    cx-page-layout.KnBrAddressBookPageTemplate,
    cx-page-layout.KnBrFactSheetPageTemplate,
    cx-page-layout.KnBrOrderImportPageTemplate,
    cx-page-layout.KnBrPurchasingAgentPageTemplate,
    cx-page-layout.KnBrServiceTicketsPageTemplate,
    cx-page-layout.KnBrQuickOrderPageTemplate,
    cx-page-layout.KnBrOrderFormsPageTemplate,
    cx-page-layout.KnBrQuickEntryPageTemplate,
    cx-page-layout.AccountPageTemplate,
    cx-page-layout.NACPageTemplate,
    cx-page-layout.KnBrMarketCentrePageTemplate,
    cx-page-layout.ContentPage1Template,
    cx-page-layout.CompanyPageTemplate {
      margin: 230px 15px 15px 15px !important;
    }
    main cx-page-layout.LandingPage2Template {
      margin-top: 175px;
    }

    cx-page-layout.CompanyPageTemplate {
      cx-org-list .list .header {
        padding: 0;
      }
    }

    cx-page-layout.KnBrDraftOrderPageTemplate,
    cx-page-layout {
      .KnBrDraftOrderListButtonSlot,
      .KnBrQuoteListButtonSlot {
        cx-generic-link {
          min-width: 100%;
          a {
            min-width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .m-res-top {
      margin-top: 15px !important;
    }

    .p-res-top {
      padding-top: 15px !important;
    }

    .p-res-right {
      padding-right: 15px !important;
    }
    .p-res-right-0 {
      padding-right: 0 !important;
    }

    .p-res-0 {
      padding: 0 !important;
    }

    .m-res-0 {
      margin: 0 !important;
    }

    .m-res-bottom {
      margin-bottom: 15px !important;
    }

    header .SiteLogo {
      flex: 0 0 42%;
    }

    header .SiteLogo cx-media img {
      width: 100px;
      height: 30px;
    }

    header cx-site-context-selector {
      padding: 0 !important;
      border: none !important;
      color: var(--cx-color-inverse);
    }

    .language {
      display: flex;
      height: 100%;
    }

    .language span {
      padding: 13px 0 !important;
    }

    header .SiteContext {
      display: flex;
      margin: 0;
      padding: 0;
    }

    header .SiteLinks {
      padding: 7px;
    }

    span.user-span {
      padding-left: 8px !important;
      padding-right: 10px !important;
    }

    .kn-br-sidebar aside {
      width: 30% !important;
    }
    .KnBrLoginPageTemplate .SiteTitle.has-components p {
      padding: 15px;
    }

    .footer-social-media ul {
      justify-content: start !important;
    }

    span.res-titles {
      display: block !important;
      float: left;
      width: auto;
      font-weight: 700;
      margin-right: 8px;
    }
    .KnBrDraftOrderPageTemplate,
    .KnBrQuotePageTemplate {
      .customersearch-list-table {
        padding-right: 0;
      }
    }
    .customersearch-list-table .customersearch-list-titles .title-wrap {
      display: block;
      width: 100%;
      padding: 10px;
    }

    .bg-white.customersearch-list-titles.table-content {
      display: block !important;
      margin-bottom: 15px;
      border-radius: 4px;
      border: 1px solid $common-border;
      margin-right: 15px;
      max-width: 48%;
    }
    .product-res-grid {
      max-width: 48%;
      margin-right: 15px;
    }
    .product-res-grid:nth-child(odd) {
      margin-right: 14px;
    }
    .bg-white.customersearch-list-titles.table-content:nth-child(odd) {
      margin: 0 !important;
    }

    .KnBrProductListPageTemplate,
    .KnBrProductGridPageTemplate,
    .KnBrSearchResultsListPageTemplate,
    .KnBrSearchResultsGridPageTemplate,
    .KnBrProductDetailsPageTemplate {
      .SearchBox.has-components {
        float: left;
        width: auto;
      }

      .Section1.has-components {
        width: 100%;
        height: auto;
      }

      .product-action-section cx-sorting {
        width: 84%;
      }

      .product-action-section {
        display: block !important;
      }

      .product-action-section .form-group {
        width: 100% !important;
      }

      .product-action-section .res-actions {
        float: right;
        text-align: right;
        display: flex;
        justify-content: flex-end;
      }

      .ProductLeftRefinements {
        width: 100%;
        float: left;
      }

      .ProductLeftRefinements button.knbr-filter-btn {
        height: auto;
        width: auto;
        font-size: 15px;
        padding: 8px;
        color: var(--cx-color-primary);
        border-color: var(--cx-color-primary);
        position: absolute;
        top: -70px;
        width: 100%;
      }

      .filter-section .fas.fa-times {
        display: block;
        color: var(--cx-color-inverse);
      }

      .product-cate-options {
        overflow: auto;
        max-height: 610px;
        height: auto;
      }

      .ProductListSlot {
        width: 100%;
      }

      kn-br-facet-list.dialog {
        z-index: 9999;
      }

      kn-br-product-intro .summary {
        width: 86%;
      }
    }

    .MultiStepCheckoutSummaryPageTemplate cx-shipping-address {
      background: var(--cx-color-inverse);
    }

    .MultiStepCheckoutSummaryPageTemplate {
      cx-delivery-mode {
        background: var(--cx-color-inverse);
      }

      cx-page-slot.BodyContent .cx-checkout-btns .col-sm-12.col-md-12.col-lg-6 {
        padding-right: 0;
      }

      cx-shipping-address .cx-shipping-address-card {
        padding: 0 0 15px 0;
      }

      cx-shipping-address .cx-checkout-body.row {
        padding: 0 15px;
      }

      cx-page-slot.BodyContent .cx-checkout-btns {
        padding-bottom: 0;
      }

      cx-page-slot.SideContent cx-checkout-order-summary cx-order-summary {
        padding: 1rem 0 0 0 !important;
      }

      cx-page-slot.SideContent {
        border: none;
      }

      cx-page-slot.BodyContent .cx-checkout-btns .col-md-12.col-lg-6 {
        padding: 0;
      }

      cx-page-slot.BodyContent {
        border: none;
        padding: 0;
      }

      .cx-review-cart-item .cx-item-list-row {
        border: 1px solid $gray-500;
        border-radius: 4px;
        margin-bottom: 15px;
        float: left;
      }

      kn-br-review-submit h4.cx-review-cart-heading {
        display: none !important;
      }

      .cx-review-cart-item .cx-item-list-row .row {
        display: block;
        width: 100%;
        margin: 0;
      }

      cx-cart-item .cx-image-container {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .cx-item-list-items .cx-info {
        max-width: 100%;
        padding: 0;
        margin: 0;
      }

      .cx-item-list-items {
        padding: 0;
      }

      .cx-item-list-items .cx-name {
        text-align: center;
        margin: 15px 0 0;
        float: left;

        width: 100%;
      }

      .cx-item-list-items .cx-name .cx-link {
        color: $filter-color;
      }

      cx-cart-item .cx-price {
        max-width: 100%;
        text-align: center;
        margin: 15px 0 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items cx-cart-item .cx-quantity {
        max-width: 100%;
        text-align: center;
        margin: 15px 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items cx-cart-item .cx-total {
        max-width: 100%;
        text-align: center;
        margin: 15px 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items .cx-code {
        text-align: center;
        padding: 0.625rem 0;
        color: $filter-color;
        float: left;
        width: 100%;
      }

      cx-cart-item-list .cx-image-container cx-media img {
        height: 115px;
      }
    }

    .KnBrOrderConfirmationPageTemplate {
      cx-order-confirmation-totals {
        flex: 0 0 100%;
      }

      cx-order-confirmation-items {
        flex: 0 0 100%;
        border: none;
        padding: 0;
      }

      .cx-item-list-row {
        border: 1px solid $gray-500;
        border-radius: 4px;
        margin-bottom: 15px;
        float: left;
      }

      .cx-review-cart-item .cx-item-list-row .row {
        display: block;
        width: 100%;
        margin: 0;
      }

      cx-cart-item .cx-image-container {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .cx-item-list-items .cx-info {
        max-width: 100%;
        padding: 0;
        flex: 0 0 100%;
        margin: 0;
      }

      .cx-item-list-items {
        padding: 0;
      }

      .cx-item-list-items .cx-name {
        text-align: center;
        margin: 15px 0 0;
        float: left;

        width: 100%;
      }

      .cx-item-list-items .cx-name .cx-link {
        color: $filter-color;
      }

      cx-cart-item .cx-price {
        max-width: 100%;
        text-align: center;
        margin: 15px 0 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items cx-cart-item .cx-quantity {
        max-width: 100%;
        text-align: center;
        margin: 15px 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items cx-cart-item .cx-total {
        max-width: 100%;
        text-align: center;
        margin: 15px 0;
        float: left;
        justify-content: center;
      }

      .cx-item-list-items .cx-code {
        text-align: center;
        padding: 0.625rem 0;
        color: $filter-color;
        float: left;
        width: 100%;
      }

      cx-cart-item-list .cx-image-container cx-media img {
        height: 115px;
      }
    }

    .modal-dialog {
      margin: 0 auto !important;
      min-width: 94% !important;
      max-width: 94% !important;
    }

    .modal-dialog-centered {
      align-items: center !important;
    }

    .modal-dialog a.btn {
      margin-left: 0 !important;
    }

    .customersearch-list-titles.product-res-titles {
      display: none;
    }

    .KnBrProductListPageTemplate
      kn-br-product-list-item.res-product-grid
      .customersearch-list-titles.product-list-content {
      display: block !important;
      text-align: center;
      border: 1px solid $common-border;
      border-radius: 4px;
      margin-bottom: 15px;
      padding: 0.5rem 0 0 !important;
    }

    .KnBrProductListPageTemplate kn-br-product-list-item.res-product-grid {
      float: left;
      width: 100%;
    }

    .KnBrProductListPageTemplate kn-br-product-list-item.res-product-grid .product-list-img {
      display: block !important;
      max-width: 100%;
    }

    .KnBrProductListPageTemplate kn-br-product-list-item.res-product-grid .customersearch-list-titles cx-media {
      width: 100%;
      height: 80px;
      margin-bottom: 15px;
    }

    .res-cart-quote {
      background: $cart-color;
      float: left;
      width: 50%;
      border-left: 1px solid var(--cx-color-inverse);
      text-align: center;
      color: var(--cx-color-inverse);
      padding: 9px;
    }

    .customersearch-list-table .customersearch-list-titles .title-wrap {
      display: block;
      max-width: 100%;
      padding: 10px;
    }

    .filter-title button.close {
      display: block;
      color: var(--cx-color-inverse);
      opacity: 1;
      font-weight: normal;
    }
    .menu-contents {
      position: relative;
      left: 0;
      border: none;
      overflow: hidden !important;
    }
    .menu-inner-titles:hover {
      background: var(--cx-color-inverse);
      display: block !important;
      float: left;
    }
    .menu-inner-titles {
      float: left;
      width: 100%;
      text-align: center;
      display: block !important;
      justify-content: center;
    }
    .res-brand-menu-list {
      float: left;
      width: 100%;
      overflow: auto;
      max-height: 350px;
    }
    .menu-inner-titles i.fa.fa-chevron-right {
      float: left !important;
      padding: 0px;
      width: 100%;
      transform: rotate(90deg);
    }
    .menu-inner-titles span {
      width: 100%;
      text-align: center !important;
    }
    .menu-contents .menu-inner-titles:hover .menu-contents-inner {
      display: block !important;
      overflow: auto;
      position: relative;
      left: 0;
    }
  }
}
