.KnBrOrderDetailsPageTemplate {
  knbr-kn-br-order-details-actions .btn {
    padding: 10px;
    font-size: 1rem;
    height: auto;
  }
}

.KnBrOrderDetailsPageTemplate {
  .BodyContent cx-link a {
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    font-size: 1rem;
    height: auto;
    text-transform: capitalize;

    &:hover {
      color: var(--cx-color-inverse);
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-primary);
    }

    &:not(:disabled):not(.disabled):active {
      color: var(--cx-color-inverse);
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-primary);
    }

    &.disabled {
      color: var(--cx-color-light);
      border-color: var(--cx-color-light);
      opacity: 1;
    }
  }

  .BodyContent cx-link {
    display: flex;
    justify-content: center;
    margin: 1.125rem;
  }
}

cx-page-layout.KnBrOrderDetailsPageTemplate knbr-kn-br-order-details-items cx-media {
  width: 70%;
  min-height: 85px;
  margin-left: auto;
  margin-right: auto;
}

cx-page-layout.KnBrOrderDetailsPageTemplate knbr-kn-br-order-details-items .unconsigned-item cx-media {
  width: 50%;
  min-height: 85px;
}
