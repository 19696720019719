:root {
  --cx-color-primary: #006bb6;
  --cx-color-secondary: #929292;
  --cx-color-inverse: #ffffff;
}

$knbr-header: var(--cx-color-inverse);
$knbr-footer: var(--cx-color-inverse);
$counter-color: var(--cx-color-inverse);
$cart-color: #000;
$sloan-green: #7bc155;

.color-knbr-footer {
  background-color: $knbr-footer;
}

.counters-section h4 {
  color: $black-color;
}
.counters-section h1 {
  color: $black-color;
  font-size: $h2-font-size;
}
.counters-section .counter-icons i {
  color: $black-color;
}
