// checkout Design

.MultiStepCheckoutSummaryPageTemplate {
  position: relative;

  cx-page-slot {
    &.TopContent {
      flex: 0 100%;
    }

    &.BodyContent {
      padding-right: 15px;
      height: 100%;

      cx-checkout-progress-mobile-top,
      cx-checkout-progress-mobile-bottom {
        display: none;
      }
    }

    &.SideContent {
      flex: 0 0 37% !important;
      border-left: 1px solid $gray-400;
      flex-direction: column;
      justify-content: flex-start;

      cx-checkout-order-summary {
        flex: unset;

        cx-order-summary {
          padding-top: 1rem;
          padding-inline-start: 2.5rem;
          padding-bottom: 0;
          padding-inline-end: 0;

          @include media-breakpoint-down(md) {
            padding: 1rem 0 0 0;
          }
        }
      }

      cx-place-order {
        flex: unset;
        padding-top: 1rem;
        padding-inline-start: 2.5rem;
        padding-bottom: 0;
        padding-inline-end: 0;

        @include media-breakpoint-down(md) {
          padding: 1rem 0 0 0;
        }
      }

      @include media-breakpoint-down(md) {
        --cx-flex-basis: 50%;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        cx-checkout-order-summary {
          width: 50%;
          padding-top: 1rem;

          cx-order-summary {
            padding: 1rem 2.5rem 0;

            @include media-breakpoint-down(md) {
              padding: 1rem 0 0 0;
            }
          }
        }

        cx-place-order {
          max-width: 50%;
          padding-bottom: 2rem;
        }
      }

      @include media-breakpoint-down(sm) {
        --cx-flex-basis: 100%;
        align-items: normal;

        cx-checkout-order-summary {
          min-width: 100%;
        }

        cx-place-order {
          max-width: 100%;
        }
      }
    }
  }

  // cx-page-slot.BodyContent {
  //   padding-right: 15px;
  //   height: 100%;
  // }

  // cx-page-slot.SideContent {
  //   flex: 0 0 37% !important;
  //   border-left: 1px solid $gray-400;
  // }

  h3.cx-checkout-title,
  h3.cx-review-title {
    padding: 25px 0;
    color: $filter-color;
    font-size: 21px;
    margin: 0;
    font-weight: bold;
  }

  cx-address-form .form-group {
    position: relative;
    width: 25%;
    max-width: 25%;
    float: left;
    padding-right: 15px;
    margin-bottom: 15px;
    padding-left: 0;
  }

  cx-address-form .form-group span.label-content {
    position: absolute;
    top: -11px;
    left: 14px;
    font-size: 14px;
    color: $filter-color;
    z-index: 1002;
    background: var(--cx-color-inverse);
  }

  cx-address-form .form-group .ng-select-container {
    padding: 12px 0 13px 5px !important;
    height: auto;
  }

  cx-address-form .form-group .ng-select-container .ng-input>input {
    padding: 7px 6px;
  }

  cx-address-form .form-group .form-control,
  cx-address-form .form-group .form-control:focus {
    padding: 13px 0 13px 15px !important;
    height: auto;
    background: var(--cx-color-inverse);
  }

  cx-address-form .row {
    float: left;
    width: 100%;
    margin: 0;
  }

  cx-address-form .col-md-12.col-lg-9 {
    max-width: 100%;
    flex: 100%;
    padding: 0;
  }

  cx-order-summary {
    .cx-summary-row {
      padding: 0.35rem 0;
      display: flex;
    }

    .cx-summary-amount {
      display: flex;
      justify-content: flex-end;
    }

    .cx-summary-total,
    .cx-card-title,
    .cx-paymentType {
      font-weight: bold;
    }
  }

  cx-page-slot.SideContent cx-checkout-order-summary {
    flex: unset;
    margin-inline-start: 1rem;
  }

  cx-page-slot.SideContent cx-order-summary {
    padding: 0px 5px 0px 20px !important;
    text-transform: capitalize;
  }

  cx-page-slot.SideContent cx-order-summary h4 {
    color: $filter-color;
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
  }

  cx-cart-item-list .cx-image-container cx-media {
    justify-content: center;
  }

  cx-cart-item-list .cx-image-container cx-media img {
    height: 70px;
  }

  cx-order-summary .cx-summary-row {
    padding: 0.35rem 0;
  }

  cx-page-slot.SideContent cx-order-summary .cx-summary-partials {
    color: $filter-color;
    font-size: 15px;
  }

  cx-page-slot.SideContent cx-order-summary .cx-summary-row .card-body {
    padding: 0px !important;
  }

  cx-page-slot.SideContent cx-order-summary .cx-summary-row h4.cx-card-title {
    font-size: 1rem;
  }

  cx-address-form {
    display: block;
    padding: inherit;
  }

  cx-delivery-mode .cx-checkout-btns {
    float: left;
    display: flex;
    width: 100%;
  }

  .cx-checkout-btns {
    float: left;
    display: flex;
    margin: 0;
    width: 100%;
  }

  cx-page-slot.BottomContent kn-br-paragraph {
    padding: 20px 0 0;
    display: flex;
    justify-content: center;
  }

  .cx-review-summary-edit-step {
    padding: 10px;
    font-weight: 600;
  }

  .cx-review-cart-total {
    padding: 30px 0px 10px;
    color: $filter-color;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
  }

  cx-page-slot.SideContent cx-place-order {
    padding: 15px 0px 0 15px;
  }

  .cx-review-cart-item {
    padding: 0;
  }

  .cx-review-cart-item .cx-item-list-row {
    padding: 15px;
    border-bottom: 1px solid $filter-color;
  }

  .cx-review-cart-item .cx-item-list-header {
    background: $customer-titles;
    padding: 15px;
    color: $filter-color;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid $filter-color;
    text-transform: capitalize;

    th {
      padding: 1rem;
    }

    .cx-item-list-desc {
      text-align: initial;
    }

    .cx-item-list-qty,
    .cx-item-list-item-price {
      text-align: center;
    }

    .cx-item-list-total {
      text-align: end;
      padding-right: 5px;
    }
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus {
    background-color: var(--cx-color-primary);
  }

  cx-shipping-address .cx-checkout-body {
    max-height: 60vh;
    overflow: auto;
  }

  cx-shipping-address p.cx-checkout-text {
    color: $filter-color;
    margin: 0;
  }

  cx-shipping-address .cx-shipping-address-card {
    padding-bottom: 15px;
  }

  cx-page-slot.BodyContent .cx-checkout-btns .col-sm-12.col-md-12.col-lg-6 {
    padding-left: 0;
    float: left;
  }

  cx-page-slot.BodyContent .cx-checkout-btns {
    justify-content: flex-start;
  }

  .cx-card-fit-to-container .card-body.cx-card-body .cx-card-actions {
    display: none;
  }

  .cx-card-fit-to-container {
    border-radius: 4px;
  }

  .cx-card-fit-to-container .card-body.cx-card-body {
    color: $filter-color;
  }

  .cx-card-fit-to-container .card-header {
    background-color: $alart-success;
    color: $success-color;
  }

  cx-checkout-progress {
    .cx-nav {
      @include media-breakpoint-only(lg) {
        @include type('5', 'semi');
      }

      font-size: $font-size-base * 1.188;
      font-weight: var(--cx-font-weight-bold);
      margin: 0;
      line-height: 26px;

      .cx-list {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .cx-item {
        display: flex;
        justify-content: center;
        flex: 1;
        color: var(--cx-color-text);
        padding: 0 0 1.375rem 0;
        border-bottom: 2px solid var(--cx-color-text);

        ::after {
          padding-inline-end: 0.75em;
          content: '';

          @include media-breakpoint-only(lg) {
            padding-inline-end: 0.5rem;
          }
        }

        &.active {
          border-color: var(--cx-color-primary);
        }

        &.disabled {
          border-color: var(--cx-color-light);
        }

        &:first-child ::before {
          padding: 0;
          content: '';
        }

        &:last-child ::after {
          padding: 0;
          content: '';
        }
      }
    }

    .cx-nav .cx-item {
      color: $filter-color;
      padding: 10px;
      font-size: 1rem;
    }

    .cx-list {
      background: $customer-titles;
    }

    .cx-link {
      color: $filter-color;
    }

    .cx-link.disabled {
      color: $checkout-progress-disabled;
    }

    .cx-link,
    .cx-link:hover {
      color: var(--cx-color-text);
      cursor: pointer;
      text-decoration: none;
      text-align: start;

      &.disabled {
        color: var(--cx-color-secondary);
        cursor: not-allowed;
        pointer-events: none;

        &::after {
          color: var(--cx-color-secondary);
        }
      }

      &.active {
        color: var(--cx-color-primary);

        &::after {
          color: var(--cx-color-primary);
        }
      }
    }

    .cx-media {
      .cx-list-media {
        display: none;
        @include type('3');
        justify-content: space-between;
        align-items: center;
        line-height: 4.75rem;
        min-width: 100%;
        border-width: 1px 0 0 0;
        border-style: solid;
        border-color: var(--cx-color-light);
        margin: 0;

        &.active {
          background-color: var(--cx-color-background);
          border: 0;
        }

        button {
          font-weight: var(--cx-font-weight-semi);
        }

        @include media-breakpoint-down(md) {
          display: flex;
          padding: 0 3.5rem;
          border-width: 0 0 1px 0;
          border-style: solid;
          border-color: var(--cx-color-light);
        }

        @include media-breakpoint-down(sm) {
          padding: 0 1.375rem;
        }
      }
    }
  }

  cx-delivery-mode .form-check .cx-delivery-label {
    width: 95%;
    align-items: center;
    margin: 10px;
  }

  .cx-review-payment-col:empty {
    display: none;
  }

  .cx-review-payment-col:empty+.cx-review-shipping-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }

  .cx-review-payment-col:empty+.cx-review-shipping-col .cx-review-card-address {
    width: 50%;
    float: left;
    border-bottom: none;
    border-right: 1px solid rgba(0, 0, 0, 0.5);
  }

  .cx-review-payment-col:empty+.cx-review-shipping-col .cx-review-card-shipping {
    width: 50%;
    float: left;
  }

  .cx-review-payment-col:empty+.cx-review-shipping-col .cx-review-summary-card .card-body {
    padding: 5px 20px;
  }

  cx-delivery-mode .form-check {
    align-items: center;
  }

  cx-delivery-mode,
  cx-payment-type {
    border: none;
    margin-top: 0px !important;
    padding-top: 0 !important;
  }
}
