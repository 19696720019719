sloan-address-form{
  .city_postalCode{
    height: 101px;
  }
  .firstName, .bldg{
    height: 80px;
  }

  cx-form-errors{
    position: absolute;
  }
}


sloan-address-card{
  .address-card {
    min-height: 233px !important;
      #delete-action{
        margin-top: 10px !important;
        button{
          min-height: 30px !important;
        }
      }
  }
}
