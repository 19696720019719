.KnBrQuotePageTemplate,
.KnBrQuoteDetailPageTemplate {

  .TopContent .KnBrQuoteListButtonSlot {
    cx-generic-link {
      width: 100%;

      a {
        width: 100%;
        text-align: center;
      }
    }
  }

  .TopContent cx-link a {
    background: var(--cx-color-primary);
    padding: 10px;
    float: left;
    color: var(--cx-color-inverse);
    border-radius: $border-radius;
    text-transform: capitalize;
    text-decoration: none;
  }

  .TopContent cx-link {
    display: flex;
    justify-content: flex-end;
  }

  .KnBrQuoteHeaderSlot cx-link,
  .KnBrEmptyCartSlot cx-link {
    flex: 0 0 14%;
    width: auto;
    float: left;
  }

  .KnBrQuoteHeaderSlot cx-link cx-generic-link a {
    color: var(--cx-color-inverse);
    font-size: 16px;
    float: left;
    text-align: center;
    padding: 7px 15px;
    background: var(--cx-color-primary);
    border-radius: 4px;
  }

  .KnBrQuoteHeaderSlot kn-br-quote-save {
    flex: 0 0 0;
    width: auto;
    float: left;
  }

  cx-page-slot.KnBrEmptyCartSlot {
    justify-content: center;
  }

  cx-page-slot.KnBrQuoteHeaderSlot {
    justify-content: flex-start;
    margin-top: 15px;
  }

  cx-page-slot.KnBrEmptyCartSlot {
    justify-content: center;
    margin-top: 15px;
  }

  cx-page-slot.KnBrQuoteActionBarSlot {
    justify-content: flex-start;
    margin-top: 15px;
  }

  .KnBrQuoteActionBarSlot kn-br-quote-delete-items {
    flex: 0 0 61px;
    width: auto;
    float: left;
  }

  .KnBrQuoteActionBarSlot kn-br-product-quick-entry,
  .KnBrEmptyCartSlot kn-br-product-quick-entry {
    flex: 0 0 130px;
    width: auto;
    float: left;
  }

  .KnBrQuoteActionBarSlot cx-link,
  .KnBrEmptyCartSlot cx-link {
    flex: 0 0 150px;
    width: auto;
    float: left;
  }

  .KnBrQuoteActionBarSlot cx-link cx-generic-link a,
  .KnBrEmptyCartSlot cx-link cx-generic-link a {
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    padding: 10px 13px;
    font-size: 1rem;
    height: auto;
    line-height: 1.34;
    float: left;
    color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
    text-decoration: unset;

    &:hover {
      color: var(--cx-color-primary);
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-inverse);
    }
  }

  kn-br-quote-item .customersearch-list-titles cx-media {
    width: 25%;
    min-height: 50px;
    float: left;
  }

  .product-name {
    float: right;
    width: 70%;
    color: $gray-600;
  }

  .product-name span {
    -webkit-line-clamp: 3;
  }

  knbr-kn-br-quote-download-link {
    flex: 1 0 15% !important;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }
}
