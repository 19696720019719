cx-page-layout.KnBrOrderFormsPageTemplate cx-media,
cx-page-layout.KnBrQuickOrderPageTemplate cx-media {
  height: 70px;
}

.KnBrOrderImportPageTemplate {
  knbr-order-import .btn {
    padding: 10px;
    font-size: 1rem;
    height: auto;
  }

  file-upload .upload-text {
    display: none;
  }

  file-upload {
    border: none;

    .upload-button {
      border: none;
    }
  }
}

.order-tools {

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: var(--cx-color-inverse);
    text-decoration: none;
    background-color: var(--cx-color-primary);
  }

  .btn-primary {
    color: var(--cx-color-inverse);
    background-color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
  }

  .btn-primary.dropdown-toggle {
    height: auto;
    color: var(--cx-color-inverse);
    background-color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    font-size: 14px;
  }
}
