kn-br-facet-navigation {
  min-width: 0;

  // hides the filter button in desktop experience
  @include media-breakpoint-up(lg) {
    button.dialog-trigger {
      display: none;
    }
  }
}

body.modal-open {
  overflow: hidden;
}

kn-br-facet-list {
  .inner {
    @include media-breakpoint-down(md) {
      max-height: 100vh;
      overflow: scroll;
    }

    padding: 0 10px 10px 10px;

    h4 {
      position: sticky;
      top: 0;
      background: var(--cx-color-background);

      padding: 0.4rem;
      padding-top: 10px;

      .close {
        opacity: 1;

        &:hover {
          color: var(--cx-color-primary);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      h4 {
        display: none;
      }
    }
  }

  &.dialog {
    @extend %cx-modal;

    >.inner {
      width: 95vw;
      height: 95vh;
    }
  }
}

$intialExpandedFacets: 0 !default;
$intialExpandedFacetsLarge: 3 !default;

%facet-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0.4rem 0;
  padding: 0.4rem;
  width: 100%;

  border-bottom: 1px solid var(--cx-color-light);

  cursor: pointer;

  font-weight: var(--cx-font-weight-semi);

  &:hover,
  &:focus {
    color: var(--cx-color-primary);
  }

  cx-icon {
    font-size: 1rem;
  }

  @include media-breakpoint-down(md) {
    cx-icon {
      font-size: 1.3rem;
    }
  }
}

kn-br-facet {
  button.heading {
    @extend %facet-heading !optional;
  }

  display: block;

  @include media-breakpoint-down(md) {
    &:nth-of-type(-n + #{$intialExpandedFacets}) {
      &.collapsed {

        .collapse-icon,
        a,
        button:not(.heading) {
          display: none;
        }
      }

      &:not(.collapsed) .expand-icon {
        display: none;
      }
    }

    &:nth-of-type(n + #{$intialExpandedFacets + 1}) {
      &:not(.expanded) {

        .collapse-icon,
        a,
        button:not(.heading) {
          display: none;
        }
      }

      &.expanded .expand-icon {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {

    // first number of facets
    &:nth-of-type(-n + #{$intialExpandedFacetsLarge}) {
      &.collapsed {

        .collapse-icon,
        a,
        button:not(.heading) {
          display: none;
        }
      }

      &:not(.collapsed) .expand-icon {
        display: none;
      }
    }

    // other facets lower in the list
    &:nth-of-type(n + #{$intialExpandedFacetsLarge + 1}) {
      &:not(.expanded) {

        .collapse-icon,
        a,
        button:not(.heading) {
          display: none;
        }
      }

      &.expanded .expand-icon {
        display: none;
      }
    }
  }

  a,
  button {
    display: flex;
    align-items: baseline;
    padding: 0rem 0.4rem;
    font-size: 14px;

    span.label {
      color: var(--cx-color-primary);
    }

    cursor: pointer;

    color: $filter-color;

    &.value {
      &:hover {
        text-decoration: none;
        color: $filter-color;
      }

      // In mobile view, we keep the facet values on a single line to avoid a lot
      // of space being used. Multi-line facet will not flow over.
      @include media-breakpoint-down(md) {
        >span {
          display: flex;
          width: calc(100% - 1.8rem);
        }

        .label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .count {
        margin-inline-start: 5px;

        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }
    }
  }

  button {
    background: none;
    border: none;
    display: inline-flex;

    &:hover {
      color: var(--cx-color-primary);
    }
  }

  &.multi-select a.value {
    &:hover {
      &:not(.selected)::before {
        border-color: $filter-color;
      }
    }

    &::before {
      content: '✓';
      color: transparent;

      flex-shrink: 0;

      font-size: 1rem;
      font-weight: bold;

      width: 1rem;
      height: 1rem;

      border-radius: none;
      border: solid 2px $filter-color;

      display: flex;
      justify-content: center;
      align-items: center;
      float: left;

      margin-inline-end: 0.5rem;
    }

    &.selected::before {
      background-color: $filter-color;
      border-color: $filter-color;
      color: var(--cx-color-inverse);
    }
  }

  &.multi-select a.value.flushVolume {
    width: 54%;
    border: 1px solid var(--cx-color-primary);
    margin: 0 10px 10px 5px;
    justify-content: center;

    &:hover {
      background-color: var(--cx-color-primary);
      border-color: var(--cx-color-primary);
      color: var(--cx-color-inverse);

      span.label {
        color: var(--cx-color-inverse);
      }
    }

    &::before {
      content: '';
      content: none;
    }

    &.selected {
      background-color: var(--cx-color-primary);
      border-color: var(--cx-color-primary);
      color: var(--cx-color-inverse);

      span.label {
        color: var(--cx-color-inverse);
      }
    }
  }

  .finish-graphite {
    color: #2c2d2d;
  }

  .finish-brushed-nickel {
    color: #978f7f;
  }

  .finish-brushed-stainless {
    color: #cfcbc3;
  }

  .finish-polished-brass {
    color: #d6c389;
  }

  .finish-polished-chrome {
    color: #e8e8e9;
  }

  .finish-rough-brass {
    color: #8f7a56;
  }

  .finish-satin-brass {
    color: #dabf88;
  }

  .finish-gun-metal {
    color: #8d918d;
  }

  .finish-matte-black {
    color: #171717;
  }

  .finish-matte-white {
    color: #fdfbf9;
  }

  .finish-off-white {
    color: #faf9f6;
  }

  .finish-polished-white {
    color: #fffdfa;
  }

  .finish-white {
    color: #ffffff;
  }
}
