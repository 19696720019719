.KnBrOrderConfirmationPageTemplate {
  .cx-page-header {
    background-color: $alart-success;
    border-radius: 4px;
  }

  .cx-order-confirmation-message {
    width: 100%;
    text-align: center;
    float: left;
    padding: 16px 0 30px;
  }

  .cx-order-confirmation-message h2 {
    color: $success;
    font-weight: bold;
  }

  .cx-order-confirmation-message p {
    float: left;
    width: 100%;
    margin: 0;
  }

  cx-order-confirmation-overview {
    margin-bottom: 20px;

    cx-order-overview {
      .cx-order-summary {
        background-color: var(--cx-color-background);

        @include media-breakpoint-down(sm) {
          background-color: var(--cx-color-inverse);
        }

        .container {
          display: flex;
          flex-wrap: wrap;
          padding: 15px 0;

          @include media-breakpoint-down(md) {
            max-width: 100%;
            min-width: 100%;
            padding: 0 1.25rem;
          }

          @include media-breakpoint-down(sm) {
            flex-direction: column;
            padding: 1.25rem;
          }

          .cx-summary-card {
            flex: 1;
            padding: 0 15px;

            &:not(:last-of-type) {
              @include media-breakpoint-up(lg) {
                border-inline-end: 1px solid var(--cx-color-text);
              }
            }

            @include media-breakpoint-down(md) {
              flex: 0 0 33%;
            }

            @include media-breakpoint-down(sm) {
              flex: 1;
              background-color: var(--cx-color-inverse);
              border-width: 1px;
              border-style: solid;
              border-color: var(--cx-color-light);
              margin: 0.625rem 0;
            }

            .cx-card-title {
              @include type('4');
              font-weight: bold;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }

  cx-order-confirmation-items {
    .cx-order-items {
      &.container {
        @include media-breakpoint-down(md) {
          max-width: 100%;
          min-width: 100%;
          padding: 0;
        }

        @include media-breakpoint-down(sm) {
          max-width: 100%;
          min-width: 100%;
          padding: 0;
        }
      }

      cx-cart-item-list {
        .cx-item-list-row {
          border-bottom: 1px solid #d3d6db;

          .cx-item-list-items {
            cx-cart-item {
              .cx-info {
                &-container {
                  .cx-price {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .cx-quantity {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .cx-total {
                    display: flex;
                    justify-content: end;
                    align-items: center;
                  }

                  .cx-code,
                  .cx-name {
                    margin-top: 0 !important;
                    line-height: var(--cx-line-height, 1.2222222222) !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .cx-order-items-header {
      @include type('4');
      padding: 1.375rem 0;
      margin: 0;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: var(--cx-color-light);

      @include media-breakpoint-down(md) {
        max-width: 100%;
        min-width: 100%;
        padding-inline-start: 2.5rem;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
        min-width: 100%;
        padding-inline-start: 1rem;
      }
    }
  }

  cx-order-confirmation-totals cx-order-summary {
    padding: 0;
  }

  // cx-order-confirmation-items .cx-order-items.container {
  //   max-width: 100%;
  //   padding: 0;
  // }

  // cx-order-confirmation-items {
  //   flex: 0 0 100%;
  //   float: left;
  //   // border-right: 1px solid $gray-500;
  //   // padding-right: 15px;
  // }

  cx-order-confirmation-totals {
    // float: left;
    // width: auto;
    // flex: 0 0 30%;
    display: none;
  }

  cx-order-confirmation-totals .col-sm-12.col-md-6.col-lg-5.col-xl-4 {
    max-width: 100%;
    flex: 100%;
  }

  cx-order-confirmation-items .cx-item-list-header {
    background: $customer-titles;
    padding: 15px;
    color: $filter-color;
    text-transform: capitalize;
    margin: auto;

    th {
      padding: 1rem;
    }

    .cx-item-list-desc {
      text-align: initial;
    }

    .cx-item-list-qty,
    .cx-item-list-price {
      text-align: center;
    }

    .cx-item-list-total {
      text-align: end;
      padding-right: 5px;
    }
  }

  cx-cart-item-list .cx-image-container cx-media img {
    height: 70px;
  }

  cx-cart-item-list .cx-item-list-row {
    padding: 15px;
  }

  cx-cart-item-list .cx-image-container cx-media {
    justify-content: center;
  }

  cx-order-confirmation-items .cx-order-items.container h4.cx-order-items-header {
    padding: 0px 0 15px;
    text-transform: capitalize;
  }
}
