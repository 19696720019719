.KnBrReturnDetailsPageTemplate {
  .BodyContent cx-link a {
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    font-size: 1rem;
    height: auto;
    text-transform: capitalize;

    &:hover {
      color: var(--cx-color-inverse);
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-primary);
    }

    &:not(:disabled):not(.disabled):active {
      color: var(--cx-color-inverse);
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-primary);
    }

    &.disabled {
      color: var(--cx-color-light);
      border-color: var(--cx-color-light);
      opacity: 1;
    }
  }

  .BodyContent cx-link {
    display: flex;
    justify-content: center;
    margin: 1.125rem;
  }

  knbr-order-return .btn {
    padding: 10px;
    height: auto;
  }
}
