.btn {
  font-weight: var(--cx-font-weight-normal) !important;
}

::-webkit-scrollbar {
  width: 15px !important;
}

cx-page-layout.KnBrProductListPageTemplate,
cx-page-layout.KnBrProductGridPageTemplate,
cx-page-layout.KnBrSearchResultsListPageTemplate,
cx-page-layout.KnBrSearchResultsGridPageTemplate,
cx-page-layout.KnBrDraftOrderPageTemplate,
cx-page-layout.KnBrQuotePageTemplate,
cx-page-layout.MultiStepCheckoutSummaryPageTemplate,
cx-page-layout.KnBrOrderConfirmationPageTemplate,
cx-page-layout.KnBrQuoteDetailPageTemplate,
cx-page-layout.KnBrDraftOrderCreatePageTemplate,
cx-page-layout.KnBrQuoteCreatePageTemplate,
cx-page-layout.KnBrDraftOrderDetailPageTemplate,
cx-page-layout.KnBrLandingPage2Template,
cx-page-layout.KnBrCustomer360PageTemplate,
cx-page-layout.KnBrOrderHistoryPageTemplate,
cx-page-layout.KnBrOrderDetailsPageTemplate,
cx-page-layout.KnBrReturnHistoryPageTemplate,
cx-page-layout.KnBrReturnDetailsPageTemplate,
cx-page-layout.KnBrInvoiceListPageTemplate,
cx-page-layout.KnBrAddressBookPageTemplate,
cx-page-layout.KnBrFactSheetPageTemplate,
cx-page-layout.KnBrOrderImportPageTemplate,
cx-page-layout.KnBrPurchasingAgentPageTemplate,
cx-page-layout.KnBrServiceTicketsPageTemplate,
cx-page-layout.KnBrQuickOrderPageTemplate,
cx-page-layout.KnBrOrderFormsPageTemplate,
cx-page-layout.KnBrQuickEntryPageTemplate,
cx-page-layout.AccountPageTemplate,
cx-page-layout.NACPageTemplate,
cx-page-layout.KnBrMarketCentrePageTemplate,
cx-page-layout.CompanyPageTemplate {
  margin: 125px 15px 15px 15px !important;
}

cx-page-layout.CompanyPageTemplate {

  cx-org-list .list .header .actions a,
  cx-org-list .list .header .actions button {
    text-transform: capitalize;
    background: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
  }

  cx-org-list a.link:focus {
    box-shadow: none;
    border: none;
    outline: none !important;
  }

  cx-org-list cx-table.vertical td {
    padding: 15px 5px;
    height: auto;
  }

  cx-org-list cx-table thead th {
    height: auto;
    text-transform: capitalize;
    font-size: 1rem;
    padding: 15px 5px;
  }
}

sloan-address-form .address-form .ng-select-container {
  font-size: 15px;
  margin-bottom: 0;
  height: auto !important;
  line-height: 1.5 !important;
}

.MultiStepCheckoutSummaryPageTemplate {
  .cx-review-cart-item .cx-item-list-header {
    background: $customer-titles;
    padding: 15px;
    color: $filter-color;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid $filter-color;
    text-transform: capitalize;
    margin: 0;
  }
}

cx-place-order {
  .cx-place-order-form {
    .cx-tc-link {
      text-decoration: none !important;
      color: var(--cx-color-primary) !important;
    }
  }
}

sloan-quote-attachment-link,
sloan-draft-order-attachment-link {
  .upload-files {
    label.upload-button {
      min-width: 48px;
      padding: 0px;
      align-items: center;
      justify-content: center;
      display: flex;
      border: none;
    }

    .fas .fa-paperclip {
      font-weight: bold !important;
      width: 48px;
      height: 48px;
      font-size: 17px;
    }
  }
}

.KnBrQuoteActionBarSlot sloan-quote-attachment-link {
  float: left;
  width: auto;
  flex: auto;
  max-width: 75%;
}

.KnBrDraftOrderActionBarSlot sloan-draft-order-attachment-link {
  float: left;
  width: auto;
  flex: auto;
  max-width: 75%;
}

.breadcrumb {
  top: 51px;
  min-height: 58px;
  position: fixed;
  z-index: 19;
}

cx-split-view:not([active-view='1']) .list table {

  th,
  td {

    &.amount,
    &.dateRange,
    // cost center
    &.currency,
    // users
    &.uid,
    &.roles,
    // permissions
    &.limit {
      opacity: 0;
      width: 0.1%;
    }

    // let's make status column smaller
    &.active {
      width: 120px !important;
    }
  }
}

.disabled-input {
  pointer-events: none;
  cursor: default;
}
