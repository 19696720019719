// ng-sidebar-container {
//   height: 100vh !important;
// }

.kn-br-sidebar {
  float: left;
  width: 20%;
  background: var(--cx-color-inverse);
  z-index: 9999 !important;
  top: 53px !important;
}

.mat-drawer-backdrop {
  z-index: 9999 !important;
}

.kn-br-sidebar .TopHeaderSlot nav a {
  width: 100%;
  padding: 20px 20px 0px 40px;
  font-size: $login-font-size;
  float: left;
  color: $filter-color;
  font-weight: $font-weight-bold;
  text-decoration: none;
}

.kn-br-sidebar .TopHeaderSlot nav {
  width: 100%;
}

.kn-br-sidebar .TopHeaderSlot nav a::before {
  text-decoration: none;
  content: '\276F';
  font-family: 'Font Awesome';
  text-align: left;
  float: right;
  position: relative;
  color: $filter-color;
  font-size: 23px;
  top: -2px;
  right: 106%;
}

.kn-br-sidebar .TopHeaderSlot ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

.accordion {
  width: 100%;
  max-width: 360px;
  margin: auto;
  background: var(--cx-color-inverse);
  border-radius: 4px;
}

.accordion .menu {
  position: relative;
  padding: 13px 15px 13px 25px;
  color: #4d4d4d;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.4s ease;
  border-bottom: 1px solid $common-border;
}

.accordion .menu .h1 {
  color: var(--cx-color-inverse);
  font-size: 2.5rem;
  text-align: center;
  padding: 50px 0;
}

.accordion li:last-child .menu {
  border-bottom: 0;
}

.accordion li i {
  position: absolute;
  top: 1.2rem;
  color: $black-color;
  transition: all 0.4s ease;
  right: 2rem;
  left: auto;
}

.accordion li i::before {
  content: '\f067';
}

.accordion li.active i::before {
  content: '\f068';
}

.submenu {
  height: 0;
  overflow: hidden;
  font-size: 15px;
  transition: height 0.4s ease;
}

.submenu li {
  padding: 11px 15px 11px 45px;
  font-weight: bold;
  border-bottom: 1px solid $common-border;
}

.accordion li:last-child .submenu {
  border-radius: 0 0 4px 4px;
}

.accordion li:last-child .submenu li:last-child {
  border-bottom: 0;
}

.kn-br-sidebar .accordion .menu a {
  color: $black-color;
  font-size: 17px;
  vertical-align: middle;
}

.kn-br-sidebar .accordion .menu a {
  &:hover {
    color: var(--cx-color-primary);
  }
}

.kn-br-sidebar .accordion .menu {
  &:hover {
    color: var(--cx-color-primary);
    background: $gray-300;
  }
}

.kn-br-sidebar .accordion .submenu li a {
  color: $black-color;
  vertical-align: middle;

  &:hover {
    color: var(--cx-color-primary);
  }
}

.kn-br-sidebar .accordion .submenu li {
  &:hover {
    background: $gray-300;
  }
}

.kn-br-sidebar .TopHeaderSlot cx-media {
  height: 25px;
  width: 25px;
  display: inline-block;
  margin-right: 15px;
}
