knbr-address-form {
  .address-form {
    font-size: 15px;

    .ng-select-container {
      font-size: 15px;
      margin-bottom: 0;
      height: auto !important;
      line-height: 1.5 !important;
    }

    .form-control {
      font-size: 15px;
      margin-bottom: 0;
      height: auto;
    }
  }
}
