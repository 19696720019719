cx-page-layout.KnBrProductDetailsPageTemplate {
  margin-top: 125px;

  @media screen and (max-width: 768px) {
    margin-top: 230px;
  }

  @extend .kn-br-container !optional;

  cx-page-slot {
    &.Summary {
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        grid-template-columns: 1fr 1fr;
        // grid-template-rows: repeat(2, auto) 1fr;
        grid-template-rows: auto;

        kn-br-product-images {
          grid-column: 1;
          grid-row: 1 / span 53;
        }

        kn-br-product-intro {
          grid-column: 2;
        }

        kn-br-product-summary {
          grid-column: 2;
        }

        cx-product-variants {
          grid-column: 2;
          grid-row: 3;
        }

        cx-product-variants:empty {
          display: none;
        }

        cx-carousel .thumb:empty {
          display: none;
        }

        kn-br-add-to-cart {
          grid-column: 2;
          padding: 0px 0px 0 20px;
        }

        kn-br-add-to-quote {
          grid-column: 2;
          padding: 20px 0px 0 20px;
        }

        knbr-kn-br-product-quote-reference {
          grid-column: 2;
          padding: 20px 0px 0 20px;
        }

        cx-stock-notification {
          grid-column: 2;
          grid-row: 3 / 4;
          padding: 20% 20px 20px 20px;
        }

        cx-stock-notification:empty {
          display: none;
        }
      }
    }

    cx-product-references {
      padding-bottom: 20px;
    }

    &.AddToCart {
      max-width: 50%;
    }

    &.UpSelling,
    &.CrossSelling,
    &.ProductFeatures {
      max-width: 1140px;
      margin: auto;
    }
  }

  .tab-delivery {
    @extend .container !optional;
  }
}
