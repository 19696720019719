.address-card {
  min-height: 202px;

  .address-header {
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .address-item {
    font-size: 15px;
    font-weight: 500;
  }

  .address-card-link {
    font-size: 14px;
    font-weight: normal;
    text-transform: capitalize;
    text-decoration: underline;
    display: inline-block;
    color: var(--cx-color-primary);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: var(--cx-color-primary);
      cursor: pointer;
    }
  }
}
