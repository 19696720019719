kn-br-facet-navigation {
  min-width: 0;

  // hides the filter button in desktop experience
  @include media-breakpoint-up(lg) {
    button.dialog-trigger {
      display: none;
    }
  }
}

body.modal-open {
  overflow: hidden;
}

kn-br-facet-list {
  .inner {
    @include media-breakpoint-down(md) {
      max-height: 100vh;
      overflow: scroll;
    }

    padding: 0 10px 10px 10px;

    h4 {
      position: sticky;
      top: 0;
      background: var(--cx-color-background);

      padding: 0.4rem;
      padding-top: 10px;

      .close {
        opacity: 1;

        &:hover {
          color: var(--cx-color-primary);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      h4 {
        display: none;
      }
    }
  }

  &.dialog {
    @extend %cx-modal;

    >.inner {
      width: 95vw;
      height: 95vh;
    }
  }
}

$intialExpandedFacets: 0 !default;
$intialExpandedFacetsLarge: 3 !default;

%facet-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px !important;
  width: 100%;
  border-bottom: 1px solid #cdcdcd;
  cursor: pointer;
  font-weight: 400 !important;
  font-size: 1.15rem;
  padding: 11px 5px 12px;
  color: #516269;
  line-height: 1.34;

  &:hover,
  &:focus {
    color: #516269;
  }

  cx-icon {
    font-size: 18px;
  }

  @include media-breakpoint-down(md) {
    cx-icon {
      font-size: 1.3rem;
    }
  }
}

kn-br-facet {
  button.heading {
    @extend %facet-heading !optional;
  }

  display: block;

  @include media-breakpoint-down(md) {
    &:nth-of-type(-n + #{$intialExpandedFacets}) {
      &.collapsed {

        .collapse-icon,
        a,
        button:not(.heading) {
          display: none;
        }
      }

      &:not(.collapsed) .expand-icon {
        display: none;
      }
    }

    &:nth-of-type(n + #{$intialExpandedFacets + 1}) {
      &:not(.expanded) {

        .collapse-icon,
        a,
        button:not(.heading) {
          display: none;
        }
      }

      &.expanded .expand-icon {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {

    // first number of facets
    &:nth-of-type(-n + #{$intialExpandedFacetsLarge}) {
      &.collapsed {

        .collapse-icon,
        a,
        button:not(.heading) {
          display: none;
        }
      }

      &:not(.collapsed) .expand-icon {
        display: none;
      }
    }

    // other facets lower in the list
    &:nth-of-type(n + #{$intialExpandedFacetsLarge + 1}) {
      &:not(.expanded) {

        .collapse-icon,
        a,
        button:not(.heading) {
          display: none;
        }
      }

      &.expanded .expand-icon {
        display: none;
      }
    }
  }

  a,
  button {
    display: flex;
    align-items: baseline;
    padding: 0.4rem;

    cursor: pointer;

    color: $filter-color;

    &.value {
      &:hover {
        text-decoration: none;
        color: $filter-color;
      }

      // In mobile view, we keep the facet values on a single line to avoid a lot
      // of space being used. Multi-line facet will not flow over.
      @include media-breakpoint-down(md) {
        >span {
          display: flex;
          width: calc(100% - 1.8rem);
        }

        .label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .count {
        margin-inline-start: 5px;

        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }
    }
  }

  button {
    background: none;
    border: none;
    display: inline-flex;

    &:hover {
      color: var(--cx-color-primary);
    }
  }

  &.multi-select a.value {
    &:hover {
      &:not(.selected)::before {
        border-color: $filter-color;
      }
    }

    &::before {
      content: '✓';
      color: transparent;

      flex-shrink: 0;

      font-size: 1rem;
      font-weight: bold;

      width: 1rem;
      height: 1rem;

      border-radius: none;
      border: solid 2px $filter-color;

      display: flex;
      justify-content: center;
      align-items: center;
      float: left;

      margin-inline-end: 0.5rem;
    }

    &.selected::before {
      background-color: $filter-color;
      border-color: $filter-color;
      color: var(--cx-color-inverse);
    }
  }
}
