body main {
  outline: none !important;
}

.KnBrLoginPageTemplate,
.knBrQuoteSellerApproverTemplate {
  display: block;
  justify-content: center;
}

.KnBrLoginPageTemplate .SiteLogo {
  position: inherit;
  text-align: left;
  top: 0;
  z-index: auto;
}

.knBrQuoteSellerApproverTemplate .SiteLogo {
  position: inherit;
  text-align: center;
  top: 0;
  z-index: auto;
}

.KnBrLoginPageTemplate .SiteTitle,
.knBrQuoteSellerApproverTemplate .SiteTitle {
  position: static;
  z-index: auto;
  top: 0;
  margin: 0 auto;
  width: fit-content;
  margin-top: 40px;
}

.KnBrLoginPageTemplate .LeftContentSlot cx-media img,
.knBrQuoteSellerApproverTemplate .LeftContentSlot cx-media img {
  width: auto;
  height: auto;
  min-height: auto;
  z-index: auto;
  max-height: 100%;
}

.KnBrLoginPageTemplate .SiteLogo cx-media,
.knBrQuoteSellerApproverTemplate .SiteLogo cx-media {
  height: 30px;
  width: auto;
}

.KnBrLoginPageTemplate .SiteLogo img,
.knBrQuoteSellerApproverTemplate .SiteLogo img {
  width: 100%;
}

// .KnBrLoginPageTemplate cx-media {
//   display: block;
// }
.KnBrLoginPageTemplate .SiteTitle.has-components p,
.knBrQuoteSellerApproverTemplate .SiteTitle.has-components p {
  color: var(--cx-color-secondary);
  font-size: 12px;
}

.login-forms .form-group .fas.fa-user {
  position: absolute;
  left: 18px;
  top: 18px;
  color: $black-color;
}

.login-forms .form-group .fas.fa-lock {
  position: absolute;
  left: 18px;
  top: 18px;
  color: $black-color;
}

.login-forms .form-group input.form-control {
  padding: 18px 48px;
}

.forget-pass a:hover {
  color: var(--cx-color-primary);
  text-decoration: none;
}

.login-forms {
  border-radius: 0;
}

.login-wrapper {
  position: static;
  top: 0;
  margin-top: 70px;
}

.login-section {
  border: 1px solid #ccc;
  box-shadow: 0 12px 14px 0 rgb(0 0 0 / 20%);
}

cx-page-layout.KnBrLoginPageTemplate .LeftContentSlot kn-br-banner {
  display: none;
}

cx-page-layout.KnBrLoginPageTemplate .LeftContentSlot sloan-login-form kn-br-banner {
  display: block;
}

cx-page-layout.KnBrLoginPageTemplate .LeftContentSlot sloan-forgot-password kn-br-banner {
  display: block;
}

cx-page-layout.KnBrLoginPageTemplate .LeftContentSlot sloan-reset-password kn-br-banner {
  display: block;
}

.login-section .form-group input.form-control {
  padding: 14px 48px;
}

.KnBrLoginPageTemplate main,
.knBrQuoteSellerApproverTemplate main {
  background-color: var(--cx-color-inverse);
}

cx-page-layout.KnBrLoginPageTemplate,
cx-page-layout.knBrQuoteSellerApproverTemplate {
  min-height: 90vh;
}
