cx-page-layout.KnBrCustomer360PageTemplate {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    cx-page-slot.TopContent {
      width: 50%;
      flex: 1 1 50%;
      padding-right: 10px;
    }

    cx-page-slot.MiddleContent {
      width: 50%;
      flex: 1 1 50%;
      padding-left: 10px;
    }
  }

  cx-page-slot.BottomContent {
    margin-top: 15px;
  }
}
