cx-cart-item-list {
  table {
    width: 100%;
  }

  .cx-table-item-container {
    display: inline-grid;
    grid-template-columns: 5rem 14rem;
    gap: 1rem;
    text-indent: 0;
  }

  .cx-name {
    margin-top: 0.5rem;
    font-size: var(--cx-font-size, 1rem);
  }

  .cx-code {
    color: var(--cx-color-secondary);
    margin-top: 0.5em;
  }

  .cx-link {
    text-decoration: none;
    color: var(--cx-color-text);
    font-weight: var(--cx-font-weight-bold);

    &:hover {
      color: var(--cx-color-primary);
    }
  }

  .cx-total {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-normal);
  }

  .cx-item-list-items {
    .cx-item {
      border-bottom: 1px solid #516269;

      .cx-total {
        text-align: end;
      }

      .cx-quantity,
      .cx-item-price {
        text-align: center;
      }
    }
  }

  .cx-cart-addons {
    display: flex;
    width: 100%;

    cx-configurator-cart-entry-info {
      margin-inline-start: 6rem;
    }
  }

  @include media-breakpoint-down(md) {
    .cx-table-item-container {
      grid-template-columns: 5em auto;

      .cx-price {
        display: none;
      }
    }

    .cx-quantity {
      @include media-breakpoint-down(sm) {
        .cx-mobile-header {
          margin-bottom: 5px;
        }
      }
    }

    .cx-value {
      cx-item-counter button[type='button'] {
        margin-bottom: 0em;
      }
    }

    .cx-actions button {
      margin-inline-start: 0;
      text-align: start;
    }

    td {
      &:nth-of-type(1) {
        padding-inline-start: 0;
      }

      &:not(:nth-of-type(1)) {
        font-size: var(--cx-font-size, 0.875rem);
        margin-inline-start: 5.7rem;
      }
    }
  }
}

kn-br-add-to-cart {
  .quantity {
    @extend .color-font;
    display: inline-flex;
    margin-bottom: 20px;

    label {
      @extend .font-text-600;
      @extend .mr-2;
    }
  }
}

kn-br-add-to-cart .quantity cx-item-counter {
  button {
    font-size: $counter-font;

    &:hover {
      color: var(--cx-color-inverse);
    }
  }
}
